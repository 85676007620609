import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Button from '@havenengineering/module-owners-shared-library/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator/LoadingIndicator';
import clsx from 'clsx';
import { DateTime, Interval } from 'luxon';

import { LettingsContext } from '../../contexts/lettings';
import { getBreakVisitType } from '../../helpers/calendar';
import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import { proseListFromArray } from '../../helpers/general';
import {
  handleLettingConfirmationOnCalendarGTM,
  handleLettingDetailsOnCalendarGTM,
} from '../../helpers/googleTag';
import { useIsMounted } from '../../hooks/useIsMounted';
import { BreakDataAction, Panels } from '../../pages/bookings';
import { TooltipType } from '../../types/onboardingTooltip';
import {
  BreakDaysType,
  getBreakDaysType,
  getBreakLabel,
  getBreakPeakType,
  getPeakTypeLabelText,
  Let2OwnSummary,
  ParkPeakType,
  PeakTypeMapping,
  SelectedBreakTransition,
} from '../helpers/bookings';
import { TooltipFactory } from '../TooltipFactory/TooltipFactory';
import styles from './BreakTransitions.module.scss';
import { LabelBadge } from './LabelBadge';

export enum RestrictedReason {
  DATE = 'restricted date',
  MAX = 'let2own maximum reached',
}

export interface FailedBreakUpdates {
  parkLettingPackageId: number;
  errorMessage: string;
  startDate: string;
  duration: number;
}

export type BreakTransitionsProps = {
  breaks: BreakDataResponse[];
  handleCancel: () => void;
  handleLetWithHaven: (
    outOfSeason: boolean,
    failedBreakUpdates: FailedBreakUpdates[]
  ) => void;
  handleSetError: (message: string) => void;
  handleSetInfo: (message: string) => void;
  handleSetAdvancedInfo: (message: { title: string; subtitle: string }) => void;
  type: Panels;
  let2OwnSummary: Let2OwnSummary | undefined;
  allPeakDatesData: PeakDatesData[];
};

export const BreakTransitions: FunctionComponent<BreakTransitionsProps> = ({
  breaks,
  handleCancel,
  handleLetWithHaven,
  handleSetError,
  handleSetInfo,
  handleSetAdvancedInfo,
  type,
  let2OwnSummary,
  allPeakDatesData,
}) => {
  const isMounted = useIsMounted();
  const { activeAccount } = useAuthContext();

  const { lettingSummary } = useContext(LettingsContext);

  const [transitions, setTransitions] = useState<
    SelectedBreakTransition[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [isBookButtonDisabled, setBookButtonDisabled] =
    useState<boolean>(false);

  const [typesOverTheLimit, setTypesOverTheLimit] = useState<
    {
      peakType: ParkPeakType;
      daysType: BreakDaysType;
      overflow: number;
    }[]
  >([]);

  const [
    maxTransitionCanBeAddedCurrentlyCounts,
    setMaxTransitionCanBeAddedCurrentlyCounts,
  ] = useState<{ [key in BreakDaysType]: { [key in ParkPeakType]: number } }>();

  const havenLetRef = useRef(null);

  const VAN_AGE_BAD_REQUEST = 400;
  const OUT_OF_SEASON_MESSAGE =
    'Your selection includes breaks that have been added into a queue on the waitlist and we will accept breaks on a first come first serve basis when they have been released.';
  const LOAD_BREAKS_ERROR_MESSAGE =
    'We’re sorry we’ve not been able to load break data for the selected time period.';
  const PUT_BREAKS_ERROR_MESSAGE =
    'We’re sorry we’ve not been able to confirm to Let with Haven.';
  const NO_BREAKS_AVAILABLE_INFO_MESSAGE =
    'There are no breaks available to Let with Haven in the selected time period.';
  const SOLD_OUT_BREAKS_INFO_MESSAGE =
    'Some of the breaks selected for this time period are sold out.';

  const getAvailableTransitionsForBreak = async (
    breakData: BreakDataResponse
  ): Promise<BreakTransition[]> => {
    if (
      activeAccount?.accountID &&
      breakData?.startDate &&
      breakData?.status?.code
    ) {
      try {
        const transitions = await fetchWrapper(
          withApiBaseUrl(
            `/plot/${activeAccount.accountID}/lettingbreaks/status?status=${breakData.status.code}&startDate=${breakData.startDate}&outOfSeason=${breakData.status.outOfSeason}`
          ),
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        return transitions;
      } catch (error) {
        handleSetError(
          error.statusCode === VAN_AGE_BAD_REQUEST && error.message
            ? error.message
            : LOAD_BREAKS_ERROR_MESSAGE
        );
      }
    }
    return [];
  };

  const appendTransitions = async () => {
    const unrestrictedBreaks = breaks.filter((br) => !br.status.restrictedDate);

    return await Promise.all(
      unrestrictedBreaks.map(async (br) => {
        const availableTransitionsForBreak: BreakTransition[] = [];

        try {
          availableTransitionsForBreak.push(
            ...(await getAvailableTransitionsForBreak(br))
          );
        } catch (e) {
          console.error(e);
        }

        return {
          ...br,
          transitions: availableTransitionsForBreak,
        };
      })
    );
  };

  useEffect(
    () => {
      setLoading(true);
      setBookButtonDisabled(false);
      appendTransitions().then((transitionBreaks) => {
        const filteredBreaks: SelectedBreakTransition[] = [];
        transitionBreaks.forEach((br) => {
          if (br.status.outOfSeason) {
            handleSetInfo(OUT_OF_SEASON_MESSAGE);
          }

          const letAction = br.transitions.find(
            (tr) => tr.action === BreakDataAction.LET
          );

          if (letAction) {
            const removeActionStatusCode = br.transitions.find(
              (tr) => tr.action === BreakDataAction.REMOVE_LET
            )?.code;

            const breakDaysType = getBreakDaysType(br.startDate, br.endDate);

            const breakPeakType = getBreakPeakType(
              br.startDate,
              br.endDate,
              allPeakDatesData
            );

            filteredBreaks.push({
              startDate: br.startDate,
              endDate: br.endDate,
              label: letAction.label,
              parkLettingPackageId: br.packageId,
              status: letAction.code,
              originalLabel: letAction.originalLabel,
              outOfSeason: br.status.outOfSeason,
              checked: true,
              disabled: false,
              removeActionStatusCode: removeActionStatusCode,
              daysType: breakDaysType,
              peakType: breakPeakType,
              capWaitlist: letAction.capWaitlist,
            });

            handleLettingDetailsOnCalendarGTM(
              letAction.label.toLowerCase() === 'waitlist'
                ? letAction.label
                : 'available',
              br.startDate,
              br.endDate,
              Interval.fromDateTimes(
                DateTime.fromFormat(br.startDate, 'yyyy-MM-dd'),
                DateTime.fromFormat(br.endDate, 'yyyy-MM-dd')
              )
                .length('days')
                .toString()
            );
          }
        });

        const newTransitions = [
          ...filteredBreaks.sort(
            (a, b) =>
              DateTime.fromISO(a.startDate).toMillis() -
              DateTime.fromISO(b.startDate).toMillis()
          ),
        ];

        if (type === Panels.LET_WITH_HAVEN && !newTransitions?.length) {
          handleSetInfo(NO_BREAKS_AVAILABLE_INFO_MESSAGE);
        }

        if (breaks.some((br) => br.status.restrictedDate)) {
          handleRestrictedMessage(breaks);
        }

        // MAX RESTRICTION CHECK
        if (let2OwnSummary?.isLet2Own) {
          const transitionStats = newTransitions.reduce(
            (transitionStats, breakTransition) => {
              if (
                breakTransition.daysType !== null &&
                breakTransition.peakType !== null
              ) {
                transitionStats[breakTransition.peakType][
                  breakTransition.daysType
                ] += 1;
              }

              return transitionStats;
            },
            {
              offPeak: {
                midweek: 0,
                weekend: 0,
              },
              peak: {
                midweek: 0,
                weekend: 0,
              },
              superPeak: {
                midweek: 0,
                weekend: 0,
              },
            }
          );

          const typesOverTheLimit: {
            peakType: ParkPeakType;
            daysType: BreakDaysType;
            overflow: number;
          }[] = [];

          [BreakDaysType.MIDWEEK, BreakDaysType.WEEKEND].forEach((daysType) =>
            [
              ParkPeakType.OFF_PEAK,
              ParkPeakType.PEAK,
              ParkPeakType.SUPER_PEAK,
            ].forEach((peakType) => {
              if (transitionStats[peakType][daysType] === 0) {
                return;
              }

              const overflow =
                let2OwnSummary.summary[peakType][daysType].current +
                transitionStats[peakType][daysType] -
                let2OwnSummary.summary[peakType][daysType].max;

              const isAboveLimit = overflow > 0;

              if (
                let2OwnSummary.summary[peakType][daysType].max !== 0 &&
                isAboveLimit
              ) {
                typesOverTheLimit.push({ daysType, peakType, overflow });
              }
            })
          );

          setTypesOverTheLimit(typesOverTheLimit);
          if (typesOverTheLimit.length) {
            const limitReachedMessage =
              constructPeakLimitMessage(typesOverTheLimit);

            handleSetAdvancedInfo(limitReachedMessage);

            const maxTransitionCanBeAddedCurrentlyCounts = {
              midweek: {
                offPeak:
                  let2OwnSummary.summary.offPeak.midweek.max -
                  let2OwnSummary.summary.offPeak.midweek.current,
                peak:
                  let2OwnSummary.summary.peak.midweek.max -
                  let2OwnSummary.summary.peak.midweek.current,
                superPeak:
                  let2OwnSummary.summary.superPeak.midweek.max -
                  let2OwnSummary.summary.superPeak.midweek.current,
              },
              weekend: {
                offPeak:
                  let2OwnSummary.summary.offPeak.weekend.max -
                  let2OwnSummary.summary.offPeak.weekend.current,
                peak:
                  let2OwnSummary.summary.peak.weekend.max -
                  let2OwnSummary.summary.peak.weekend.current,
                superPeak:
                  let2OwnSummary.summary.superPeak.weekend.max -
                  let2OwnSummary.summary.superPeak.weekend.current,
              },
            };
            setMaxTransitionCanBeAddedCurrentlyCounts(
              maxTransitionCanBeAddedCurrentlyCounts
            );

            // set default selection
            const checkedTransitionCounts = {
              midweek: {
                offPeak: 0,
                peak: 0,
                superPeak: 0,
              },
              weekend: {
                offPeak: 0,
                peak: 0,
                superPeak: 0,
              },
            };

            const limitedNewTransitions = newTransitions.map(
              (breakTransition) => {
                if (
                  typesOverTheLimit.some(
                    ({ daysType, peakType }) =>
                      breakTransition.daysType === daysType &&
                      peakType === breakTransition.peakType
                  )
                ) {
                  const isTransitionDisabled =
                    maxTransitionCanBeAddedCurrentlyCounts[
                      breakTransition.daysType!
                    ][breakTransition.peakType!] <=
                    checkedTransitionCounts[breakTransition.daysType!][
                      breakTransition.peakType!
                    ];

                  checkedTransitionCounts[breakTransition.daysType!][
                    breakTransition.peakType!
                  ] += 1;

                  return {
                    ...breakTransition,
                    checked: !isTransitionDisabled,
                    disabled: isTransitionDisabled,
                  };
                } else {
                  return breakTransition;
                }
              }
            );

            setTransitions(limitedNewTransitions);
          } else {
            setTransitions(newTransitions);
          }
        } else {
          setTransitions(newTransitions);
        }

        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breaks, JSON.stringify(breaks)]
  );

  const constructPeakLimitMessage = (
    typesOverTheLimit: {
      peakType: ParkPeakType;
      daysType: BreakDaysType;
      overflow: number;
    }[]
  ) => {
    const breaks = typesOverTheLimit.reduce(
      (limitObject: Record<string, string[]>, br) => {
        if (
          !limitObject[br.peakType] ||
          !limitObject[br.peakType].includes(br.daysType)
        ) {
          limitObject[br.peakType] = [
            ...(limitObject[br.peakType] || []),
            br.daysType,
          ];
        }

        return limitObject;
      },
      {}
    );

    const breakLists = Object.entries(breaks).map((entry) => {
      const dayList = proseListFromArray(entry[1], 'and');
      const lowerCase = true;
      return `${getPeakTypeLabelText(
        entry[0] as ParkPeakType,
        lowerCase
      )} ${dayList}`;
    });

    const title = `You've reached the limit for ${proseListFromArray(
      breakLists,
      'and'
    )} breaks.`;
    const subtitle = 'You’ll need to unselect before changing your selection.';

    return {
      title,
      subtitle,
    };
  };

  const handleRestrictedMessage = (breaks: BreakDataResponse[]) => {
    if (
      breaks.some((br) => br.status.restrictedReason === RestrictedReason.DATE)
    )
      handleSetInfo(SOLD_OUT_BREAKS_INFO_MESSAGE);

    if (
      breaks.some((br) => br.status.restrictedReason === RestrictedReason.MAX)
    ) {
      const maxRestrictedBreaks = breaks.filter(
        (br) => br.status.restrictedReason === RestrictedReason.MAX
      );
      const breakTypes = maxRestrictedBreaks.reduce((types: string[], br) => {
        const peakDate = allPeakDatesData.find(
          (peak) => peak.startDate === br.startDate
        );
        const peakType = peakDate?.isSuperPeakDate
          ? PeakTypeMapping.superPeak
          : peakDate?.isPeakDate
          ? PeakTypeMapping.peak
          : PeakTypeMapping.offPeak;

        const startDay = DateTime.fromISO(br.startDate).weekday;
        const dayType =
          startDay === 5 ? 'weekend' : startDay === 1 ? 'mid-week' : '';

        const typeString = `${peakType} ${dayType}`;

        return types.includes(typeString) ? types : [...types, typeString];
      }, []);

      const message = `You have reached the maximum number of ${proseListFromArray(
        breakTypes,
        'and'
      )} breaks you can book.`;
      handleSetInfo(message);
    }
  };

  useEffect(() => {
    if (
      transitions?.length === 0 ||
      !transitions?.some((breakTransition) => breakTransition.checked)
    ) {
      setBookButtonDisabled(true);
    } else {
      setBookButtonDisabled(false);
    }
  }, [transitions]);

  const requestBreakDataChange = async () => {
    if (transitions?.length) {
      const breaks = transitions.filter((tr) => tr.checked);
      const outOfSeasonPresent = transitions.find((tr) => tr.outOfSeason);
      performBreakDataChange(breaks, !!outOfSeasonPresent);
    }
  };

  const performBreakDataChange = async (
    breaks: SelectedBreakTransition[],
    outOfSeasonPresent?: boolean
  ) => {
    setButtonLoading(true);
    try {
      const response = await fetchWrapper(
        withApiBaseUrl(`/plot/${activeAccount?.accountID}/lettingbreaks`),
        {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify({
            breaks: breaks.map((br) => ({
              startDate: br.startDate,
              status: br.status,
              capWaitlist: br.capWaitlist,
            })),
          }),
        }
      );

      breaks.forEach((b) => {
        handleLettingConfirmationOnCalendarGTM(
          b.label.toLowerCase() === 'waitlist' ? b.label : 'available',
          b.startDate,
          b.endDate,
          Interval.fromDateTimes(
            DateTime.fromFormat(b.startDate, 'yyyy-MM-dd'),
            DateTime.fromFormat(b.endDate, 'yyyy-MM-dd')
          )
            .length('days')
            .toString()
        );
      });

      handleLetWithHaven(!!outOfSeasonPresent, response?.failedBreakUpdates);
    } catch (error) {
      handleSetError(
        error.statusCode === VAN_AGE_BAD_REQUEST && error.message
          ? error.message
          : PUT_BREAKS_ERROR_MESSAGE
      );
    } finally {
      if (isMounted) {
        setButtonLoading(false);
      }
    }
  };

  const handleSelection = (startDate: string) => {
    const newTransitions = [...(transitions as SelectedBreakTransition[])].map(
      (tr) => {
        return {
          ...tr,
          checked: tr.startDate === startDate ? !tr.checked : tr.checked,
        };
      }
    );

    if (
      let2OwnSummary?.isLet2Own &&
      maxTransitionCanBeAddedCurrentlyCounts &&
      typesOverTheLimit?.length
    ) {
      const checkedTransitionCounts = newTransitions.reduce(
        (_checkedTransitionCounts, breakTransition) => {
          if (breakTransition.checked) {
            _checkedTransitionCounts[breakTransition.daysType!][
              breakTransition.peakType!
            ] += 1;
          }

          return _checkedTransitionCounts;
        },
        {
          midweek: {
            offPeak: 0,
            peak: 0,
            superPeak: 0,
          },
          weekend: {
            offPeak: 0,
            peak: 0,
            superPeak: 0,
          },
        }
      );

      const limitedNewTransitions = newTransitions.map((breakTransition) => {
        if (breakTransition.checked) {
          return breakTransition;
        }

        const isTransitionDisabled =
          maxTransitionCanBeAddedCurrentlyCounts[breakTransition.daysType!][
            breakTransition.peakType!
          ] <=
          checkedTransitionCounts[breakTransition.daysType!][
            breakTransition.peakType!
          ];

        return {
          ...breakTransition,
          checked: false,
          disabled: isTransitionDisabled,
        };
      });

      setTransitions(limitedNewTransitions);
    } else {
      setTransitions(newTransitions);
    }
  };

  const handleWaitlistSelection = (usingHolidayHome: boolean) => {
    if (transitions?.length) {
      const breaks = transitions.filter((t) =>
        !usingHolidayHome
          ? t.checked
            ? t.status
            : t.removeActionStatusCode
          : t.removeActionStatusCode
      );
      performBreakDataChange(breaks);
    }
  };

  return (
    <>
      {loading && (
        <div className={styles.loading} data-testid="loading-indicator">
          <LoadingIndicator loading />
        </div>
      )}

      {transitions && transitions.length > 0 && (
        <>
          <div>
            {transitions?.map((tr, idx) => (
              <div key={tr.startDate} ref={idx === 0 ? havenLetRef : null}>
                <div className={styles.breakContainer}>
                  <div
                    data-testid="transition-checkbox"
                    className={clsx(
                      styles.bookCheckbox,
                      tr.checked ? styles.checked : '',
                      tr.disabled ? styles.disabled : ''
                    )}
                    onClick={() =>
                      !tr.disabled && handleSelection(tr.startDate)
                    }
                  >
                    <img src="/assets/check-white.svg" alt="" />
                  </div>
                  <div>
                    <LabelBadge label={tr.label.toLowerCase()} />
                    <div className={styles.dates}>{`${DateTime.fromISO(
                      tr.startDate
                    ).toFormat('ccc dd LLL')} - ${DateTime.fromISO(
                      tr.endDate
                    ).toFormat('ccc dd LLL yyyy')}`}</div>
                    <div className={styles.numOfNights}>
                      {getBreakLabel(
                        tr.startDate,
                        tr.endDate,
                        getBreakVisitType(tr.status),
                        allPeakDatesData,
                        lettingSummary?.let2Own
                      )}
                    </div>
                  </div>
                </div>
                {idx === 0 && (
                  <TooltipFactory
                    tooltipType={TooltipType.CALENDAR_EVENT_CHECKBOX}
                    parentRef={havenLetRef}
                    placement={'bottom'}
                  />
                )}
              </div>
            ))}
          </div>

          {type === Panels.WAITLIST_AD ? (
            <div className={styles.waitlistAdInfo}>
              These dates are proving more popular than ever, we originally
              added your Holiday Home to the waitlist but can now accept these
              dates to let with us.
              <div className={styles.waitlistAdQuestion}>
                Would you still like to let with Haven?
              </div>
              <Button
                className={styles.waitlistAdButton}
                onClick={() => handleWaitlistSelection(false)}
                isLoading={buttonLoading}
              >
                Yes, let selected dates with Haven
              </Button>
              <Button
                className={styles.waitlistAdButton}
                variant="outlined"
                onClick={() => handleWaitlistSelection(true)}
              >
                No, I’m using my Holiday Home
              </Button>
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                className={styles.cancelButton}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={requestBreakDataChange}
                isLoading={buttonLoading}
                className={styles.bookButton}
                disabled={isBookButtonDisabled}
              >
                Book
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
