import React, { FunctionComponent, useState } from 'react';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { SelectInputField } from '@havenengineering/module-shared-library/dist/form/components/SelectInput';
import { TextInputField } from '@havenengineering/module-shared-library/dist/form/components/TextInput';
import useForm from '@havenengineering/module-shared-library/dist/form/hooks/useForm';

import {
  modeOfTransportOptions,
  timeSlotsOptions,
} from '../../../helpers/arrivals';
import { ArrivalBookingForm, BookingType } from '../Booking';
import styles from './ArrivalDetailsStep.module.scss';
import { Stepper } from './Stepper';

type ArrivalDetailsStepProps = {
  handleNext: (data: any) => void;
  handlePrev: () => void;
  form: Partial<ArrivalBookingForm>;
  bookingType: BookingType;
};

export const ArrivalDetailsStep: FunctionComponent<ArrivalDetailsStepProps> = ({
  handleNext,
  handlePrev,
  form,
  bookingType,
}) => {
  const [error, setError] = useState<string>('');

  const { fields, submit } = useForm<any>({
    initialState: {
      timeArrival: form.timeArrival || '',
      modeOfTransport: form.modeOfTransport || '',
      licencePlate1: form.licencePlateNumber?.[0] || '',
      licencePlate2: form.licencePlateNumber?.[1] || '',
    },
    schema: {
      timeArrival: {
        required: bookingType !== BookingType.OWNER,
      },
      modeOfTransport: {
        required: true,
      },
    },
    submitCallback: async (state) => {
      if (state.modeOfTransport === 'Car' && !state.licencePlate1)
        return setError('Licence Plate is required');

      handleNext({
        modeOfTransport: state.modeOfTransport,
        licencePlateNumber:
          state.modeOfTransport === 'Car'
            ? [state.licencePlate1, state.licencePlate2]
            : [],
        timeArrival: state.timeArrival,
      });
    },
  });

  return (
    <form
      className={styles.mainContainer}
      onSubmit={(event) => event.preventDefault()}
    >
      <h1 className={styles.label}>Arrival details</h1>
      {bookingType !== BookingType.OWNER ? (
        <p>
          Please let us know an arrival time and mode of transport. Our busiest
          arrival times are between 12pm and 6pm Monday to Friday so it might be
          worth trying to avoid these times.
        </p>
      ) : (
        <p>Please let us know the mode of transport.</p>
      )}

      <div className={styles.editorContainer}>
        {bookingType !== BookingType.OWNER && (
          <SelectInputField
            id="timeArrival"
            className="fs-mask"
            label="Time of arrival"
            field={fields.timeArrival}
            options={timeSlotsOptions}
            isSearchable={false}
          />
        )}

        <SelectInputField
          id="modeOfTransport"
          label="Mode of Transport"
          field={fields.modeOfTransport}
          options={modeOfTransportOptions}
        />
        {fields.modeOfTransport.value === 'Car' && (
          <>
            <TextInputField
              className="fs-mask"
              id="licencePlate1"
              label="Licence Plate 1"
              field={fields.licencePlate1}
              type="text"
            />
            <TextInputField
              className="fs-mask"
              id="licencePlate2"
              label="Licence Plate 2"
              field={fields.licencePlate2}
              type="text"
            />
          </>
        )}

        {error && (
          <InlineMessaging
            type={InlineMessagingTheme.ERROR_THEME}
            title="Error"
            message={error}
            dismissible={false}
          />
        )}
      </div>

      <Stepper
        handleNext={() => submit()}
        handlePrev={() => handlePrev()}
        nextIsDisabled={false}
        prevIsDisabled={false}
        dotsCount={3}
        activeDot={1}
      />
    </form>
  );
};
