import { DateTime, Info, Interval } from 'luxon';

export const now = () => {
  return DateTime.now().setLocale('en-GB').setZone('Europe/London');
};

export const convertArrivalTimeTo24format = (timeArrival: string) => {
  const arrivalArray = timeArrival.split('-');

  const formattedArrival = arrivalArray.map((arrival) => {
    const pm = arrival.trim().slice(-2).toLowerCase() === 'pm';
    let time = arrival.trim().slice(0, -2).replace('.', '');

    if (pm && parseInt(time) < 1200) {
      time = '' + (parseInt(time) + 1200);
    }

    if (time.length < 4) {
      time = `0${time}`;
    }
    return time;
  });

  return formattedArrival.join(' - ');
};

export const formatCalendarDayToLocal = (date: DateTime) => {
  const zonedDateTime = DateTime.utc(date.year, date.month, date.day)
    .setLocale('en-GB')
    .setZone('Europe/London');

  const weekDayString = zonedDateTime.toFormat('ccc');
  const dateMonthString = zonedDateTime.toFormat('dd LLL');

  return `${weekDayString.toUpperCase()}, ${dateMonthString}`;
};

export const getDateTimeFromIso = (date: string) => {
  return DateTime.fromISO(date).setLocale('en-GB').setZone('Europe/London');
};

export const formatIsoToLocal = (date: string, format = 'ccc dd LLL yyyy') => {
  return DateTime.fromISO(date)
    .setLocale('en-GB')
    .setZone('Europe/London')
    .toFormat(format);
};

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

export const secondsToWdhms = (incSeconds: number) => {
  if (incSeconds <= 0) {
    return {
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  let seconds = incSeconds;

  const weeks = Math.floor(seconds / (3600 * 24 * 7));
  seconds -= weeks * 3600 * 24 * 7;
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return {
    weeks,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const getMonthNameFromNumber = (monthNo: number) => {
  return Info.months()[monthNo - 1];
};

export const getDurationInDays = (startDate: DateTime, endDate: DateTime) => {
  const duration = endDate.diff(startDate, 'days');
  return duration.days;
};

export const isWithinInterval = (
  startDate: DateTime,
  endDate: DateTime,
  comparisonDate: DateTime
): boolean => {
  const interval = Interval.fromDateTimes(startDate, endDate);
  return interval.contains(comparisonDate);
};

export interface DateRange {
  startDate: DateTime;
  endDate: DateTime;
}

export const areDateRangesOverlapping = (
  dateRange1: DateRange,
  dateRange2: DateRange
) =>
  dateRange1.startDate < dateRange2.endDate &&
  dateRange2.startDate < dateRange1.endDate;
