import React, { FunctionComponent } from 'react';
import Button from '@havenengineering/module-owners-shared-library/dist/components/Button';

import styles from './BookingNavigationHeader.module.scss';

interface BookingNavigationHeaderProps {
  monthLabel: string;
  handleTodayClick: () => void;
  onKeyClick: () => void;
  onAddEventClick: () => void;
  mobileView: boolean;
}

const BookingNavigationHeader: FunctionComponent<
  BookingNavigationHeaderProps
> = ({
  monthLabel,
  handleTodayClick,
  onKeyClick,
  onAddEventClick,
  mobileView,
}) => {
  return (
    <div className={styles.header}>
      <span>{monthLabel}</span>
      <div className={styles.actions}>
        <Button onClick={handleTodayClick} variant="text">
          Today
        </Button>
        <Button onClick={onKeyClick} variant="text">
          Key
        </Button>
        <div>
          <Button onClick={onAddEventClick} variant="text">
            <img
              src="https://www.haven.com/assets/owners/shared/plus.svg"
              alt="plus"
              role="presentation"
            />
            {!mobileView && 'Add booking'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BookingNavigationHeader;
