import React, { FunctionComponent } from 'react';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { TextInputField } from '@havenengineering/module-shared-library/dist/form/components/TextInput';
import useForm, {
  ukPhoneValidator,
} from '@havenengineering/module-shared-library/dist/form/hooks/useForm';

import { MaintenanceForm } from '../Booking';
import styles from './MaintenanceDetailsStep.module.scss';
import { Stepper } from './Stepper';

type MaintenanceDetailsStepProps = {
  handleNext: (data: any) => void;
  handlePrev: () => void;
  form: MaintenanceForm;
  nextIsDisabled: boolean;
  bookingError: string;
};

export const MaintenanceDetailsStep: FunctionComponent<
  MaintenanceDetailsStepProps
> = ({ handleNext, handlePrev, form, nextIsDisabled, bookingError }) => {
  const { fields, submit } = useForm<MaintenanceForm>({
    initialState: {
      firstName: form.firstName,
      lastName: form.lastName,
      phoneNumber: form.phoneNumber,
    },
    schema: {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      phoneNumber: {
        required: true,
        validator: ukPhoneValidator,
      },
    },
    submitCallback: async (state) => {
      handleNext(state);
    },
  });

  return (
    <form
      className={styles.mainContainer}
      onSubmit={(event) => event.preventDefault()}
    >
      <h1 className={styles.label}>Maintenance visit details</h1>
      <p className={styles.text}>
        Please provide contact details or company name below. We will only
        contact them should we need to before or during their visit.
      </p>
      <div className={styles.editorContainer}>
        <TextInputField
          className="fs-mask"
          id="firstName"
          label="Contact first name"
          field={fields.firstName}
          type="text"
        />
        <TextInputField
          className="fs-mask"
          id="surname"
          label="Contact surname"
          field={fields.lastName}
          type="text"
        />
        <TextInputField
          className="fs-mask"
          id="phoneNumber"
          label="Contact number"
          field={fields.phoneNumber}
          type="text"
        />
      </div>

      {bookingError && (
        <InlineMessaging
          type={InlineMessagingTheme.SUCCESS_THEME}
          title="Success"
          message={bookingError}
          dismissible={false}
        />
      )}

      <Stepper
        handleNext={() => submit()}
        handlePrev={() => handlePrev()}
        nextIsDisabled={nextIsDisabled}
        prevIsDisabled={false}
        dotsCount={3}
        activeDot={2}
        nextLabel="Book"
      />
    </form>
  );
};
