import getConfig from 'next/config';
import urlJoin from 'url-join';

const {
  publicRuntimeConfig: {
    PUBLIC_APP_URL,
    PUBLIC_API_URL,
    PUBLIC_DOCUMENTS_API_URL,
    PUBLIC_ARRIVALS_API_URL,
    PUBLIC_PAYMENT_API_URL,
    PUBLIC_LETTINGS_API_URL,
    PUBLIC_PLAY_PASS_API_URL,
    PUBLIC_ACCOUNT_API_URL,
    PUBLIC_LOGIN_URL,
    PUBLIC_EAGLE_EYE_API_URL,
    PUBLIC_AUTHENTICATION_API_URL,
  },
} = getConfig();

type RequestOptions = RequestInit & {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
};

export class FetchError extends Error {
  constructor(message: string, public readonly statusCode: number) {
    super(message);
  }
}

export const withBFFUrl = (suffix: string): string =>
  urlJoin(PUBLIC_APP_URL || '', suffix);

export const withApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_API_URL || '', suffix);

export const withDocumentsApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_DOCUMENTS_API_URL || '', suffix);

export const withArrivalsApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_ARRIVALS_API_URL || '', suffix);

export const withPaymentApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_PAYMENT_API_URL || '', suffix);

export const withLettingsApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_LETTINGS_API_URL || '', suffix);

export const withPlayPassApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_PLAY_PASS_API_URL || '', suffix);

export const withAccountApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_ACCOUNT_API_URL || '', suffix);

export const withEagleEyeApiBaseUrl = (suffix: string): string =>
  urlJoin(PUBLIC_EAGLE_EYE_API_URL || '', suffix);

export const withAuthenticationUrl = (suffix: string): string =>
  urlJoin(PUBLIC_AUTHENTICATION_API_URL || '', suffix);

export const fetchWrapper = async <T = any>(
  url: string,
  options: RequestOptions = {},
  unauthRedirect = true
): Promise<T> => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (headers['Content-Type'] === 'multipart/form-data') {
    // @ts-ignore
    delete headers['Content-Type'];
  }

  const res = await fetch(url, {
    ...options,
    credentials: options.credentials
      ? options.credentials
      : !options.method || options.method.toLowerCase() === 'get'
      ? 'omit'
      : 'include',
    headers,
  });

  if (res?.ok) {
    if (res.status === 204) return {} as T;
    let payload = null;
    switch (headers.Accept) {
      case 'application/pdf': {
        payload = await res.blob();
        break;
      }
      default: {
        payload = await res.json();
      }
    }
    return payload;
  } else {
    if (res?.status === 404) {
      throw new FetchError(res.statusText, res.status);
    }
    const error = await res?.json();
    const status = error?.statusCode || res?.status || 500;
    if (unauthRedirect && process.browser && status === 401) {
      window.location.assign(
        `${PUBLIC_LOGIN_URL}?sessionExpired=true&redirect=${window.location.href}`
      );
    }
    throw new FetchError(error?.message, status);
  }
};

export const swrFetcher = (url: string) =>
  fetchWrapper(withApiBaseUrl(url), { method: 'GET', credentials: 'include' });

export const swrLocalFetcher = (url: string) =>
  fetchWrapper(withBFFUrl(url), { method: 'GET', credentials: 'include' });
