import { DateTime } from 'luxon';

import { isWithinInterval } from '../../helpers/dateTime';
import { LettingConfigEntry } from '../../types/lettingConfig';

export const displayOneOffPayment = (
  config: LettingConfigEntry[] | undefined,
  currentDate: string
) => {
  const partialPaymentConfig = JSON.parse(
    config?.find(
      (entry: LettingConfigEntry) =>
        entry?.name === 'let2ownPartialPaymentDateRange'
    )?.value || '[]'
  );

  if (!partialPaymentConfig?.startDate) {
    return false;
  }

  const today = DateTime.fromISO(currentDate);

  const formattedStartDate = DateTime.fromFormat(
    partialPaymentConfig.startDate,
    'dd/MM/yyyy'
  );

  const formattedEndDate = DateTime.fromFormat(
    partialPaymentConfig.endDate,
    'dd/MM/yyyy'
  );

  return isWithinInterval(formattedStartDate, formattedEndDate, today);
};
