import { useContext } from 'react';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';

import SiteFeeHolding from '../components/SiteFeeHolding/SiteFeeHolding';
import { LettingsContext } from '../contexts/lettings';
import { MainLayout } from '../layouts/MainLayout';

type SiteFeeHoldingWrapper = {
  children: JSX.Element;
  showSiteFeeHolding?: boolean;
};

export const SiteFeeHoldingWrapper = ({
  children,
  showSiteFeeHolding = false,
}: SiteFeeHoldingWrapper) => {
  const { activeAccount } = useAuthContext();
  const { lettingSummary } = useContext(LettingsContext);

  if (!activeAccount || !showSiteFeeHolding) {
    return children;
  }

  if (showSiteFeeHolding && !lettingSummary?.let2Own) {
    return (
      <MainLayout>
        <SiteFeeHolding />
      </MainLayout>
    );
  }

  return children;
};
