import React, { FunctionComponent } from 'react';
import Link from 'next/link';

import styles from './BackButton.module.scss';

type Props = {
  pathname?: string;
  handleClick?: () => void;
};

export const BackButton: FunctionComponent<Props> = ({
  children = <>Back</>,
  pathname,
  handleClick,
}) => {
  if (pathname) {
    return (
      <Link href={pathname} legacyBehavior>
        <a className={styles.link}>{children}</a>
      </Link>
    );
  }

  if (handleClick !== undefined) {
    return (
      <a onClick={() => handleClick()} className={styles.link}>
        {children}
      </a>
    );
  }

  return <a className={styles.link}>{children}</a>;
};
