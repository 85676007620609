import { fetchWrapper, withApiBaseUrl } from './fetch';

type Park = {
  parkName: string;
  parkCode: string;
  parkRegion: string;
};

export const getParkByCode = async (
  parkCode: string,
  unauthRedirect = true
): Promise<Park | null> =>
  await fetchWrapper(
    withApiBaseUrl(`/parks/${parkCode}`),
    {
      method: 'GET',
      credentials: 'include',
    },
    unauthRedirect
  );
