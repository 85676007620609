import React, { FunctionComponent } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';

import styles from './CounterButtons.module.scss';

type Props = {
  count: number;
  testId?: string;
  onIncrement: (increasedCount: number) => void;
  onDecrement: (decreasedCount: number) => void;
};

export const CounterButtons: FunctionComponent<Props> = ({
  count,
  testId = '',
  onIncrement,
  onDecrement,
}) => (
  <div className={styles.buttonContainer}>
    <Button
      variant="outlined"
      onClick={() => onDecrement(count - 1)}
      disabled={count === 0}
      data-testid={`${testId}-decrement`}
      type="button"
    >
      <img
        src="https://www.haven.com/assets/icons/minus.svg"
        width={24}
        height={24}
        alt="remove"
      />
    </Button>
    <strong data-testid={`${testId}-count`}>{count}</strong>
    <Button
      variant="outlined"
      onClick={() => onIncrement(count + 1)}
      data-testid={`${testId}-increment`}
      type="button"
    >
      <img
        src="https://www.haven.com/assets/icons/plus.svg"
        width={24}
        height={24}
        alt="add"
      />
    </Button>
  </div>
);
