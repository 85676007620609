import styles from './EventListItem.module.scss';

export const EventListPartExItem = ({ label }: { label: string }) => {
  return (
    <div className={styles.partExItem}>
      <div className={styles.imageWrapper}>
        <img
          src="https://www.haven.com/assets/owners/shared/holiday_home.svg"
          alt=""
        />
      </div>
      <div className={styles.text}>
        <p className={styles.heading}>Pick up new keys!</p>
        <p className={styles.date}>
          {label} (expected part-exchange completion)
        </p>
      </div>
    </div>
  );
};
