import React, { useState } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import {
  DateInputField,
  DateValue,
} from '@havenengineering/module-shared-library/dist/form/components/DateInput';
import { SelectInputField } from '@havenengineering/module-shared-library/dist/form/components/SelectInput';
import useForm from '@havenengineering/module-shared-library/dist/form/hooks/useForm';
import { DateTime } from 'luxon';

import { timeSlotsOptions } from '../../helpers/arrivals';
import {
  getDateTimeFromIso,
  getDurationInDays,
  now,
} from '../../helpers/dateTime';
import {
  updateBooking,
  wouldArrivalsBeAboveTheMaximumConsecutiveDays,
} from '../helpers/bookings';
import { useBooking } from './bookingContext';
import styles from './DateEditor.module.scss';

type DateEditorForm = {
  dateArrival: DateValue;
  dueToLeaveDate: DateValue;
  timeArrival: string | null;
};

const DateEditor = () => {
  const { booking, arrivals, handleDateUpdate } = useBooking();

  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { fields, submit } = useForm<DateEditorForm>({
    initialState: {
      dateArrival: getDateTimeFromIso(booking.dateArrival).toJSDate(),
      dueToLeaveDate: getDateTimeFromIso(booking.dueToLeaveDate).toJSDate(),
      timeArrival: booking.timeArrival,
    },
    schema: {
      dateArrival: {
        required: true,
      },
      dueToLeaveDate: {
        required: true,
      },
      timeArrival: {
        required: booking.isLetting,
      },
    },
    submitCallback: async (state) => {
      handleSubmit(state);
    },
  });

  const handleSubmit = async (state: any) => {
    setError('');
    setSuccess(false);

    const startDate = DateTime.fromJSDate(state.dateArrival).startOf('day');
    const endDate = DateTime.fromJSDate(state.dueToLeaveDate).startOf('day');

    if (endDate < startDate) {
      return setError('Selected range are invalid');
    }

    const MAXIMUM_CONTINUOUS_BOOKING = 60;

    if (getDurationInDays(startDate, endDate) > MAXIMUM_CONTINUOUS_BOOKING) {
      return setError(
        `A single booking cannot exceed ${MAXIMUM_CONTINUOUS_BOOKING} days`
      );
    }

    if (
      arrivals &&
      wouldArrivalsBeAboveTheMaximumConsecutiveDays(
        startDate,
        endDate,
        arrivals.filter(
          (arrival) => arrival.data?.shortCode !== booking.shortCode
        ),
        MAXIMUM_CONTINUOUS_BOOKING
      )
    ) {
      return setError(
        `Consecutive private lets and owner visits cannot exceed ${MAXIMUM_CONTINUOUS_BOOKING} days`
      );
    }

    setLoading(true);
    try {
      const dateArrival = DateTime.fromJSDate(state.dateArrival).toISODate();
      const dueToLeaveDate = DateTime.fromJSDate(
        state.dueToLeaveDate
      ).toISODate();

      const response = await updateBooking(
        {
          modeOfTransport: booking.modeOfTransport,
          licencePlateNumber: booking.licencePlateNumber,
          timeArrival: state.timeArrival,
          dueToArriveDate: dateArrival,
          dueToLeaveDate: dueToLeaveDate,
        },
        booking
      );
      handleDateUpdate({
        timeArrival: response.timeArrival,
        dueToArriveDate: response.dateArrival,
        dueToLeaveDate: response.dueToLeaveDate,
      });
      setSuccess(true);
    } catch (error) {
      setError('Something went wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className={styles.editorContainer}
      onSubmit={(event) => event.preventDefault()}
      data-testid="date-editor"
    >
      <DateInputField
        className="fs-mask"
        id="dateArrival"
        label="Arrival date"
        field={fields.dateArrival}
        minDate={now().toJSDate()}
        disabled={now() > DateTime.fromISO(booking.dateArrival)}
      />
      <DateInputField
        className="fs-mask"
        id="dueToLeaveDate"
        label="Departure date"
        field={fields.dueToLeaveDate}
        minDate={now().toJSDate()}
      />
      {booking.isLetting && (
        <SelectInputField
          id="timeArrival"
          className="fs-mask"
          label="Arrival time"
          field={fields.timeArrival}
          options={timeSlotsOptions}
          isSearchable={false}
        />
      )}
      {error && (
        <InlineMessaging
          type={InlineMessagingTheme.ERROR_THEME}
          title="Error"
          message={error}
          dismissible={false}
        />
      )}
      <Button onClick={() => submit()} isLoading={loading}>
        Save Dates
      </Button>
      {success && (
        <InlineMessaging
          type={InlineMessagingTheme.SUCCESS_THEME}
          title="Success"
          message="Update was successful"
          dismissible={false}
        />
      )}
    </form>
  );
};

export default DateEditor;
