import React, { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';

import styles from './LettingAdSuccess.module.scss';

type Props = {
  handleOk: () => void;
  activeLettingAd?: Advert;
};

export const LettingAdSuccess: FunctionComponent<Props> = ({
  handleOk,
  activeLettingAd,
}) => {
  const router = useRouter();
  const { loggedInUser } = useAuthContext();

  return (
    <div className={styles.lettingAdSuccess}>
      <div className={styles.successIcon}>
        <img src="/assets/check-white.svg" alt="" />
      </div>
      <div className={styles.successTitle}>Yay! Thank you</div>
      <div className={styles.successEmail}>
        A confirmation email has been sent to{' '}
        <span className="fs-mask">{loggedInUser?.email}</span>
      </div>
      <div className={styles.successUpdated}>
        We’ve updated these breaks within{' '}
        <span onClick={() => router.push('/bookings')}>Manage bookings</span>{' '}
        and let {activeLettingAd?.lettingAdministrator || 'your letting admin'}{' '}
        know so you can sit back and relax.{' '}
        {activeLettingAd?.lettingAdministratorEmail &&
        activeLettingAd?.lettingAdministratorPhone
          ? 'Should you need to contact your admin their details are as follows:'
          : ''}
        <br />
        <span>{activeLettingAd?.lettingAdministratorEmail}</span> <br />{' '}
        {activeLettingAd?.lettingAdministratorPhone}
      </div>
      <Button onClick={handleOk}>Ok, got it!</Button>
    </div>
  );
};
