export interface LettingApplicationFields {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  accountNumber: string;
  isPetFriendly: boolean;
  isPrivatelyLet: boolean;
  isTermsAndConditionsAccepted: boolean;
  hasSafetyTest: boolean;
  hasRepairCoverPlan: boolean;
  isAcceptedToLet: boolean;
  type: ApplicationType;
  status: ApplicationStatus;
}

export enum BreakVisitType {
  LET_WITH_HAVEN,
  WAITLIST,
  OWNER_OR_PRIVATE,
  MAINTENANCE,
  NONE,
}

enum ApplicationType {
  flexi = 'FLEXI',
  fixed = 'FIXED',
}

export enum ApplicationStatus {
  draft = 'DRAFT',
  submitted = 'SUBMITTED',
}

export interface LettingAdvertisement {
  displayAd: boolean;
  lastYearOfLet?: number;
}

export interface LettingAdvertForBanner {
  id: string;
  title: string;
  heading: string;
  text: string;
  imageUrl?: string;
  ctaText: string;
  contactDetails?: ContactDetails;
}

interface ContactDetails {
  name: string;
  jobTitle: string;
  phone: string;
  email: string;
}

export interface LettingMarketingPageContent {
  [key: string]: any;
  revision: number;
  createdAt: number;
  updatedAt: number;
  contentType: 'lettingMarketingPage';
  id: string;
  badge?: string;

  mainBannerImage?: string;
  mainBannerImageMobile?: string;
  mainBannerTitle?: string;
  mainBannerContent?: string;
  mainBannerCallToAction?: string;
  mainBannerCallToActionLink?: string;

  whyChooseLettingTitle?: string;
  whyChooseLettingFirstBlockImage?: string;
  whyChooseLettingFirstBlockContent?: string;
  whyChooseLettingSecondBlockImage?: string;
  whyChooseLettingSecondBlockContent?: string;
  whyChooseLettingThirdBlockImage?: string;
  whyChooseLettingThirdBlockContent?: string;
  whyChooseLettingFourthBlockImage?: string;
  whyChooseLettingFourthBlockContent?: string;

  careImage?: string;
  careContent?: string;

  repairCoverImage?: string;
  repairCoverTitle?: string;
  repairCoverContent?: string;
  repairCoverCallToAction?: string;
  repairCoverCallToActionLink?: string;

  howItWorksTitle?: string;
  howItWorksFirstBlockImage?: string;
  howItWorksFirstBlockContent?: string;
  howItWorksFirstBlockCallToAction?: string;
  howItWorksFirstBlockCallToActionLink?: string;

  howItWorksSecondBlockImage?: string;
  howItWorksSecondBlockContent?: string;
  howItWorksSecondBlockCallToAction?: string;
  howItWorksSecondBlockCallToActionLink?: string;

  questionTitle?: string;
  questionContent?: string;
  questionCallToAction?: string;
  questionCallToActionLink?: string;
}

export interface LettingSummary {
  incomeStillToEarn: number;
  lettingGrade: number;
  frozenSubletIncome: number;
  purchaseDebt: number;
  incomeTransferred: number;
  totalBreaksIncome: number;
  endOfSeasonProjectedIncome: number;
  fixedOrFlexi: string;
  typeOfLetting: string;
  petFriendly: boolean;
  acceptedForLetting: boolean;
  let2Own: boolean;
  isRenewing?: boolean;
  renewalCompleted?: boolean;
  partialPaymentMade?: boolean;
}

export interface LettingAccount {
  readyForRenewal: boolean;
  isRenewing: boolean;
}
