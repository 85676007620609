import { useEffect, useState } from 'react';

export const useScreenWidth = (maxWidth: number) => {
  const [isBelowWidth, setIsBelowWidth] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${maxWidth}px)`);
    setIsBelowWidth(mql?.matches);
    const screenTest = (e: any) => {
      setIsBelowWidth(e?.matches);
    };
    mql?.addEventListener('change', screenTest);
    return () => mql?.removeEventListener('change', screenTest);
  }, [maxWidth]);

  return isBelowWidth;
};
