import React from 'react';
import { EventData } from '@havenengineering/module-owners-shared-library/dist/components/EventCalendar';

type BookingContextType = {
  booking: ArrivalBooking;
  arrivals?: EventData<ArrivalBooking>[];
  handleTransportUpdate: (body: UpdateBookingTransportBody) => void;
  handleDateUpdate: (body: UpdateBookingDateBody) => void;
  handleUpsertParticipants: (body: UpsertParticipantsBody) => void;
  handleCancel: () => void;
};

const BookingContextDefaultValues: BookingContextType = {
  booking: {} as ArrivalBooking,
  arrivals: [],
  handleTransportUpdate: () => {},
  handleDateUpdate: () => {},
  handleUpsertParticipants: () => {},
  handleCancel: () => {},
};

export const BookingContext = React.createContext<BookingContextType>(
  BookingContextDefaultValues
);
export const useBooking = () => React.useContext(BookingContext);
