/* eslint-disable */
import Script from 'next/script';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';

const QualtricsSurveyIntercepts = () => {
  const { userType } = useAuthContext();
  if (userType !== 'USER') return null;
  return (
    <>
      <div id="ZN_bwSqrEPRagAKCnY" className="qualtricsSurveryIntercepts" />
      <Script
        src="/scripts/qualtrics-survery.js"
        type="text/javascript"
        id="qualtrics-survery-intercepts-script"
      ></Script>
    </>
  );
};

export default QualtricsSurveyIntercepts;
