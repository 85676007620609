import { LettingConfigEntry } from '../types/lettingConfig';
import { ApplicationStatus, LettingApplicationFields } from '../types/lettings';
import { fetchWrapper, withApiBaseUrl } from './fetch';

export const parseRestrictedParks = (config?: LettingConfigEntry[] | null) =>
  JSON.parse(
    config?.find((entry) => entry?.name === 'restrictedParks')?.value || '[]'
  );

export const parseLettingConfigItem = (
  config = [] as LettingConfigEntry[],
  configName: string
) =>
  JSON.parse(
    config?.find((entry) => entry?.name === configName)?.value || '[]'
  );

export const parseMaximumAgeForLetting = (
  config?: LettingConfigEntry[] | null
) => {
  const maximumAge =
    config?.find((entry) => entry?.name === 'maximumAgeForLetting')?.value || 0;
  return +maximumAge;
};

export const isParkValidForLettingMarketing = (
  restrictedParks: string[],
  parkCode: string
) => !restrictedParks?.includes(parkCode);

export const getLettingApplicationsByAccountNo = async (accountNo: string) => {
  try {
    const body = await fetchWrapper(withApiBaseUrl(`/letting/application`), {
      method: 'GET',
      credentials: 'include',
    });

    return body?.rows?.filter(
      (application: LettingApplicationFields) =>
        application.accountNumber === accountNo
    );
  } catch (error) {
    // error while fetching letting applications
  }
};

export const accountIsValidForLetting = async (account: UserAccount) => {
  const applications = await getLettingApplicationsByAccountNo(
    account.accountNo
  );
  const submitted = applications?.find(
    (application: LettingApplicationFields) =>
      application.status === ApplicationStatus.submitted
  );

  return {
    isValid: account.acceptedForLet || !!submitted?.id,
    hasSubmitted: !!submitted?.id,
  };
};
