import React, { FunctionComponent, useMemo } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button/Button';
import { isSameDate } from '@havenengineering/module-owners-shared-library/dist/components/EventCalendar/utils';
import clsx from 'clsx';

import { formatCalendarDayToLocal } from '../../helpers/dateTime';
import { InfoSelection } from './InfoPanel';
import styles from './PanelHeader.module.scss';

type PanelHeaderProps = {
  withCloseIcon?: boolean;
  handleCloseActionBar?: () => void;
  selection?: InfoSelection;
  className?: string;
  title?: string;
};

export const PanelHeader: FunctionComponent<PanelHeaderProps> = ({
  withCloseIcon = true,
  handleCloseActionBar,
  selection,
  className,
  title,
}) => {
  const dayLabelString = useMemo(() => {
    if (selection?.dates) {
      const { dates } = selection;
      if (isSameDate(dates.startDate, dates.endDate)) {
        return formatCalendarDayToLocal(dates.startDate);
      }

      const formattedStartDate = formatCalendarDayToLocal(dates.startDate);
      const formattedEndDate = formatCalendarDayToLocal(dates.endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  }, [selection]);

  return (
    <div className={clsx(styles.header, className)}>
      <p className={styles.label}>{title || dayLabelString}</p>
      {withCloseIcon && (
        <Button
          data-testid="close"
          variant="text"
          className={styles.closeButton}
          onClick={handleCloseActionBar}
        >
          <img
            src="https://www.haven.com/assets/icons/close.svg"
            width={16}
            height={16}
            alt="close"
          />
        </Button>
      )}
    </div>
  );
};
