import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import styles from './InlineMessage.module.scss';

type InlineMessageProps = {
  type: 'error' | 'info';
  message: string;
  onClick: () => void;
  className?: string;
};

export const InlineMessage: FunctionComponent<InlineMessageProps> = ({
  type,
  message,
  onClick,
  className,
}) => (
  <div className={clsx(styles.InlineMessage, styles[type], className)}>
    <div>{message}</div>
    <div className={styles.closeIcon} onClick={onClick}>
      <img src="/assets/close_small.svg" alt="" />
    </div>
  </div>
);
