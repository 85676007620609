import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import Button from '@havenengineering/module-owners-shared-library/dist/components/Button';
import { EventData } from '@havenengineering/module-owners-shared-library/dist/components/EventCalendar';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';
import { isSameDate } from '@havenengineering/module-owners-shared-library/dist/utils';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator/LoadingIndicator';
import { DateTime } from 'luxon';

import {
  getBreakVisitType,
  isLetWithHaven,
  PARK_CLOSED_INFO_MESSAGE,
  REMOVE_BREAK_ERROR_MESSAGE,
  REMOVE_LET_2_OWN_BELOW_MINIMUM_ERROR_MESSAGE,
  REMOVE_LET_TIME_ERROR_MESSAGE,
} from '../../helpers/calendar';
import { fetchWrapper, withArrivalsApiBaseUrl } from '../../helpers/fetch';
import { handleArrivalBookingOnCalendarGTM } from '../../helpers/googleTag';
import { useIsMounted } from '../../hooks/useIsMounted';
import useOnboardingTooltips from '../../hooks/useOnboardingTooltips';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import {
  ArrivalDataEnum,
  BookingTab,
  BreakDataAction,
  BreakEventStatus,
  EventDateSelection,
  Panels,
  ParkClosedDataTypeEnum,
  StyleTypes,
} from '../../pages/bookings';
import { TooltipType } from '../../types/onboardingTooltip';
import { BackButton } from '../BackButton/BackButton';
import { ArrivalBookingEdit } from '../BookingDetails/ArrivalBookingEdit';
import { BookingProvider } from '../BookingDetails/BookingProvider';
import {
  getBreakDataForSelection,
  getBreakTransitions,
  isBreakRemovalDisabled,
  Let2OwnSummary,
  performBreakDataChange,
  sortBookingDataByArrivalDate,
} from '../helpers/bookings';
import { LetWithHavenBookingEdit } from '../LetWithHavenEdit/LetWithHavenEdit';
import { TooltipFactory } from '../TooltipFactory/TooltipFactory';
import { Booking } from './Booking';
import { BreakTransitions, FailedBreakUpdates } from './BreakTransitions';
import { EventListItem } from './EventListItem';
import { InfoSelection } from './InfoPanel';
import styles from './InfoPanelView.module.scss';
import { LettingAdSuccess } from './LettingAdSuccess';
import { LettingPartialSuccess } from './LettingPartialSuccess';
import { PanelHeader } from './PanelHeader';

export type InfoPanelViewProps = {
  handleCloseActionBar: () => void;
  selection: InfoSelection;
  arrivals?: EventData<ArrivalBooking>[];
  handleArrivalEdit: (editedBooking: ArrivalBooking, tagging?: boolean) => void;
  handleArrivalBooking: (shortCode: string) => void;
  letWithHavenBookingToEdit?: BreakDataResponse;
  handleLetWithHavenEdit: (letWithHavenBookingToEdit: {
    startDate: string;
  }) => void;
  handleLetWithHaven: (outOfSeason: boolean) => void;
  initialPanel?: Panels;
  activeLettingAd?: Advert;
  handleDateSelectionChange: (newSelection: EventDateSelection) => void;
  letWithHavenEnabled: boolean;
  isParkClosed: boolean;
  allowedToLet: boolean;
  parkAllowsLetting: boolean;
  hasSubmittedApplication: boolean;
  firstEventStatus: BreakEventStatus;
  tabView: BookingTab;
  initialArrivalBookingToEdit?: ArrivalBooking;
  refetchEventData: (closeActionBar?: boolean) => void;
  activeSeason: number;
  ownersCards: OwnersCardResponse | null;
  allPeakDatesData: PeakDatesData[];
  let2OwnSummary?: Let2OwnSummary;
  setCalendarLoading: (isLoading: boolean) => void;
};

export const InfoPanelView: FunctionComponent<InfoPanelViewProps> = ({
  handleCloseActionBar,
  selection,
  arrivals,
  handleArrivalEdit,
  handleArrivalBooking,
  letWithHavenBookingToEdit,
  handleLetWithHavenEdit,
  handleLetWithHaven,
  initialPanel,
  activeLettingAd,
  handleDateSelectionChange,
  letWithHavenEnabled,
  isParkClosed,
  allowedToLet,
  parkAllowsLetting,
  hasSubmittedApplication,
  firstEventStatus,
  tabView,
  initialArrivalBookingToEdit = null,
  refetchEventData,
  activeSeason,
  ownersCards,
  allPeakDatesData,
  let2OwnSummary,
  setCalendarLoading,
}) => {
  const { activeAccount } = useAuthContext();
  const router = useRouter();
  const isMounted = useIsMounted();

  const [events, setEvents] = useState<EventData[]>([]);
  const [
    selectedArrivalBookingForEditing,
    setSelectedArrivalBookingForEditing,
  ] = useState<any | null>(initialArrivalBookingToEdit);
  const [
    selectedLetWithHavenBookingForEditing,
    setSelectedLetWithHavenBookingForEditing,
  ] = useState<any | null>(letWithHavenBookingToEdit);
  useEffect(() => {
    setSelectedLetWithHavenBookingForEditing(letWithHavenBookingToEdit);
  }, [letWithHavenBookingToEdit]);
  const [currentPanel, setCurrentPanel] = useState<Panels>(Panels.INDEX);
  const [selectionBreakData, setSelectionBreakData] = useState<
    BreakDataResponse[]
  >([]);
  const [error, setError] = useState<string>('');
  const [info, setInfo] = useState<string>('');
  const [advancedInfo, setAdvancedInfo] = useState<{
    title: string;
    subtitle: string;
  } | null>(null);
  const [advancedSuccess, setAdvancedSuccess] = useState<{
    title: string;
    subtitle: string;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [failedBreakUpdatesForPanel, setFailedBreakUpdatesForPanel] = useState<
    FailedBreakUpdates[] | null
  >(null);

  const isEventfulSelection = !!events.length;

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { tooltip } = useOnboardingTooltips(TooltipType.CALENDAR_EVENT_BOOK);
  useEffect(() => {
    setShowTooltip(!!tooltip);
  }, [tooltip]);
  const mobileView = useScreenWidth(800);

  const bookButtonRef = useRef(null);
  const ownerBookingRef = useRef(null);

  useEffect(() => {
    if (tabView === BookingTab.LIST && selection.events?.length) {
      const event = selection.events[0];
      if (event.code === ArrivalDataEnum.ARRIVAL_BOOKING) {
        arrivalBookingEdit(event.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.events, tabView]);

  useEffect(() => {
    setInfo('');
    setAdvancedInfo(null);
    setError('');
    const bookingEvents = selection.events?.filter(
      (e) => e.styleType !== StyleTypes.DAY_STYLES_ONY
    );
    setEvents(bookingEvents || []);
    if (initialPanel) {
      setCurrentPanel(initialPanel);
    }

    letWithHavenEnabled &&
      activeAccount &&
      getBreakDataForSelection(activeAccount?.accountID, selection).then(
        (breakData) => {
          isMounted && setSelectionBreakData(breakData?.breaks);
        }
      );

    const isParkClosed = selection?.events?.some(
      (event) => event.code === ParkClosedDataTypeEnum.PARK_CLOSED
    );

    !isOverSevenDaysAway(selection.dates.startDate) &&
      letWithHavenEnabled &&
      setInfo(
        'If you’re adding a let with Haven break within 7 days of arrival, it will be added to a waitlist.'
      );

    !isSameDate(selection.dates.startDate, selection.dates.endDate) &&
      isParkClosed &&
      setInfo(PARK_CLOSED_INFO_MESSAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    if (initialPanel) {
      setCurrentPanel(initialPanel);
      setInfo('');
      setError('');
    }
  }, [initialPanel]);

  const isOverSevenDaysAway = (date: DateTime): boolean => {
    const sevenDaysFromNow = DateTime.now().plus({ days: 7 });
    return date > sevenDaysFromNow;
  };

  const cancelArrivalBooking = async (booking: ArrivalBooking) => {
    setLoading(true);
    const shortCode = booking.shortCode;
    try {
      await fetchWrapper(withArrivalsApiBaseUrl(`/booking/${shortCode}`), {
        method: 'DELETE',
        credentials: 'include',
      });
      setSelectedArrivalBookingForEditing((prev: ArrivalBooking) => ({
        ...prev,
        isCancelled: true,
      }));

      handleArrivalEdit(
        {
          ...booking,
          isCancelled: true,
        },
        false
      );

      handleArrivalBookingOnCalendarGTM(booking, 'cancel arrival');
      handleCloseActionBar();
    } catch (error) {
      setError('Something went wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  const handleBookClick = () => {
    setCurrentPanel(Panels.BOOKING);
    setInfo('');
    setAdvancedInfo(null);
    setAdvancedSuccess(null);
    setError('');
    setFailedBreakUpdatesForPanel(null);
  };

  const onArrivalBooking = (shortCode: string) => {
    handleArrivalBooking(shortCode);
    handleCloseActionBar();
  };

  const onLetWithHaven = (outOfSeason?: boolean) => {
    handleLetWithHaven(!!outOfSeason);
    handleCloseActionBar();
  };

  const onLetWithHavenSuccess = (
    outOfSeason: boolean,
    failedBreakUpdates?: FailedBreakUpdates[]
  ) => {
    handleLetWithHaven(!!outOfSeason);
    setFailedBreakUpdatesForPanel(failedBreakUpdates || null);
    setCurrentPanel(
      failedBreakUpdates?.length
        ? Panels.LET_PARTIAL_SUCCESS
        : Panels.LET_SUCCESS
    );
  };

  const arrivalBookingEdit = (booking: ArrivalBooking) => {
    setSelectedArrivalBookingForEditing(booking);
    setCurrentPanel(Panels.ARRIVAL_EDIT);
  };

  const letWithHavenBookingEdit = (booking: BreakDataResponse) => {
    setAdvancedSuccess(null);
    setSelectedLetWithHavenBookingForEditing(booking);
    setCurrentPanel(Panels.LET_WITH_HAVEN_EDIT);
  };

  const renderArrivalBookingSelect = (
    eventSelection: EventData,
    firstArrivalBookingId?: string
  ) => {
    return (
      <div
        key={eventSelection.id}
        className={styles.breakAndTooltipWrapper}
        ref={
          eventSelection.id === firstArrivalBookingId ? ownerBookingRef : null
        }
      >
        <EventListItem
          event={eventSelection}
          handleEdit={(eventSelection) =>
            arrivalBookingEdit(eventSelection.data as ArrivalBooking)
          }
          handleCancel={(eventSelection) =>
            cancelArrivalBooking(eventSelection.data as ArrivalBooking)
          }
          isRemovalDisabled={isBreakRemovalDisabled(
            eventSelection,
            allPeakDatesData,
            let2OwnSummary
          )}
          breakVisitType={getBreakVisitType(eventSelection.code)}
          allPeakDatesData={allPeakDatesData}
        />
        {eventSelection.id === firstArrivalBookingId && renderAmendTooltip()}
      </div>
    );
  };

  const renderAmendTooltip = () => (
    <>
      {(mobileView || !showTooltip) && (
        <TooltipFactory
          tooltipType={TooltipType.CALENDAR_EVENT_AMEND}
          parentRef={ownerBookingRef}
        />
      )}
    </>
  );

  const renderBookTooltip = () => (
    <>
      {!mobileView && (
        <TooltipFactory
          tooltipType={TooltipType.CALENDAR_EVENT_BOOK}
          handleDismiss={() => setShowTooltip(false)}
          parentRef={bookButtonRef}
        />
      )}
    </>
  );

  const renderLetEventSelect = (
    eventSelection: EventData<BreakDataResponse>
  ) => (
    <EventListItem
      key={eventSelection.id}
      event={eventSelection}
      handleEdit={(eventSelection) =>
        letWithHavenBookingEdit(eventSelection.data)
      }
      handleCancel={removeBreak}
      isRemovalDisabled={isBreakRemovalDisabled(
        eventSelection,
        allPeakDatesData,
        let2OwnSummary
      )}
      breakVisitType={getBreakVisitType(eventSelection.code)}
      allPeakDatesData={allPeakDatesData}
    />
  );

  const removeBreak = async (eventSelection: EventData) => {
    setLoading(true);
    if (activeAccount?.accountID) {
      try {
        const accountId = activeAccount.accountID;
        const code = eventSelection.code;
        const startDate = eventSelection.data.startDate;
        const outOfSeason = eventSelection.data.status.outOfSeason;
        const withinSixWeeks = eventSelection.data.status.withinSixWeeks;

        if (isLetWithHaven(code) && withinSixWeeks) {
          return setInfo(REMOVE_LET_TIME_ERROR_MESSAGE);
        }

        if (
          isBreakRemovalDisabled(
            eventSelection,
            allPeakDatesData,
            let2OwnSummary
          )
        ) {
          return setInfo(REMOVE_LET_2_OWN_BELOW_MINIMUM_ERROR_MESSAGE);
        }

        const transitions = await getBreakTransitions(
          accountId,
          code,
          startDate,
          outOfSeason
        );

        if (!transitions.length) {
          throw new Error();
        }

        const removeBreakTransition = transitions.find(
          (tr: BreakTransition) => tr.action === BreakDataAction.REMOVE_LET
        );

        if (!removeBreakTransition) {
          throw new Error();
        }

        const success = await performBreakDataChange(accountId, [
          {
            startDate: eventSelection.data.startDate,
            status: removeBreakTransition.code,
          },
        ]);

        if (!success) {
          throw new Error();
        }

        const closeActionBar = true;
        refetchEventData(closeActionBar);
      } catch (error) {
        setError(REMOVE_BREAK_ERROR_MESSAGE);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderEventList = useCallback(
    () => {
      const sortedEvents = sortBookingDataByArrivalDate(events);

      const firstArrivalBookingId = sortedEvents.find(
        (event) => event.code === ArrivalDataEnum.ARRIVAL_BOOKING
      )?.id;

      return (
        <>
          {sortedEvents.map((event) =>
            event.code === ArrivalDataEnum.ARRIVAL_BOOKING
              ? renderArrivalBookingSelect(event, firstArrivalBookingId)
              : renderLetEventSelect(event)
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events]
  );

  const handleArrivalBookingChange = (
    changedArrivalBooking: ArrivalBooking
  ) => {
    setSelectedArrivalBookingForEditing(changedArrivalBooking);
    handleArrivalEdit(changedArrivalBooking);
    setEvents((prev) => {
      const original = prev.filter(
        (p) => p?.data?.shortCode !== changedArrivalBooking.shortCode
      );
      const changed = prev.find(
        (p) => p?.data?.shortCode === changedArrivalBooking.shortCode
      );
      return changed
        ? [...original, { ...changed, data: changedArrivalBooking }]
        : original;
    });
  };

  const selectedBreaks = useMemo(
    () =>
      activeLettingAd?.relevantBreaks?.map((br) => {
        return {
          startDate: br.startDate,
          endDate: br.endDate,
          status: {
            code: br.status,
            outOfSeason: false,
            components: [],
          },
        };
      }) as BreakDataResponse[],
    [activeLettingAd?.relevantBreaks]
  );

  return (
    <>
      <div className={styles.infoPanel}>
        {currentPanel === Panels.LET_SUCCESS && (
          <LettingAdSuccess
            handleOk={() => handleCloseActionBar()}
            activeLettingAd={activeLettingAd}
          />
        )}
        {currentPanel === Panels.LET_PARTIAL_SUCCESS && (
          <LettingPartialSuccess
            handleOk={() => handleCloseActionBar()}
            activeLettingAd={activeLettingAd}
            failedBreakUpdates={failedBreakUpdatesForPanel}
          />
        )}
        <PanelHeader
          selection={selection}
          handleCloseActionBar={handleCloseActionBar}
        />

        {![Panels.INDEX].includes(currentPanel) &&
          isEventfulSelection &&
          [Panels.ARRIVAL_EDIT, Panels.LET_WITH_HAVEN_EDIT].includes(
            currentPanel
          ) &&
          tabView !== BookingTab.LIST && (
            <BackButton
              handleClick={() => {
                setCurrentPanel(Panels.INDEX),
                  setInfo(''),
                  setError(''),
                  setAdvancedInfo(null),
                  setAdvancedSuccess(null);
              }}
            />
          )}

        {loading && (
          <div className={styles.loading}>
            <LoadingIndicator loading />
          </div>
        )}

        <div className={styles.infoPanelContent}>
          <div className={styles.infoPanelMessage}>
            {error && (
              <InlineMessaging
                type={InlineMessagingTheme.ERROR_THEME}
                message={error}
                className={styles.inlineMessage}
              />
            )}
            {info && (
              <InlineMessaging
                type={InlineMessagingTheme.WARNING_THEME}
                message={info}
                dismissible={false}
                className={styles.inlineMessage}
              />
            )}
            {advancedInfo && (
              <InlineMessaging
                className={styles.advancedInfoBox}
                type={InlineMessagingTheme.INFO_THEME}
                title={
                  <div>
                    <strong>{advancedInfo.title}</strong>{' '}
                    <span>{advancedInfo.subtitle}</span>
                  </div>
                }
                dismissible={false}
              />
            )}
            {advancedSuccess && (
              <InlineMessaging
                className={styles.advancedInfoBox}
                type={InlineMessagingTheme.SUCCESS_THEME}
                title={
                  <div>
                    <strong>{advancedSuccess.title}</strong>{' '}
                    <span>{advancedSuccess.subtitle}</span>
                  </div>
                }
                dismissible={false}
              />
            )}
          </div>

          {currentPanel === Panels.INDEX && (
            <div className={styles.summaryView}>
              {isEventfulSelection && renderEventList()}
            </div>
          )}

          {currentPanel === Panels.ARRIVAL_EDIT &&
            selectedArrivalBookingForEditing?.shortCode && (
              <BookingProvider
                initialBooking={selectedArrivalBookingForEditing}
                arrivals={arrivals}
              >
                <ArrivalBookingEdit
                  handleArrivalBookingChange={handleArrivalBookingChange}
                />
              </BookingProvider>
            )}

          {currentPanel === Panels.LET_WITH_HAVEN_EDIT &&
            selectedLetWithHavenBookingForEditing && (
              <LetWithHavenBookingEdit
                booking={selectedLetWithHavenBookingForEditing}
                setCalendarLoading={setCalendarLoading}
                onError={setError}
                onSave={() => {
                  handleLetWithHavenEdit(selectedLetWithHavenBookingForEditing);
                  setAdvancedSuccess({
                    title: 'Success!',
                    subtitle: 'Update was successful.',
                  });
                  setCurrentPanel(Panels.INDEX);
                }}
                onCancel={() => {
                  setCurrentPanel(Panels.INDEX),
                    setInfo(''),
                    setError(''),
                    setAdvancedInfo(null),
                    setAdvancedSuccess(null);
                }}
                allPeakDatesData={allPeakDatesData}
              />
            )}

          {currentPanel === Panels.BOOKING && (
            <Booking
              selection={selection}
              handleArrivalBooking={onArrivalBooking}
              handlePromoteLetting={() => router.push('/lettings-hub')}
              handleDateSelectionChange={handleDateSelectionChange}
              handleSetInfo={setInfo}
              handleSetAdvancedInfo={setAdvancedInfo}
              handleSetError={setError}
              selectionBreakData={selectionBreakData}
              onLetWithHaven={onLetWithHaven}
              onLetWithHavenSuccess={onLetWithHavenSuccess}
              letWithHavenEnabled={letWithHavenEnabled}
              isParkClosed={isParkClosed}
              allowedToLet={allowedToLet}
              parkAllowsLetting={parkAllowsLetting}
              hasSubmittedApplication={hasSubmittedApplication}
              firstEventStatus={firstEventStatus}
              activeSeason={activeSeason}
              ownersCards={ownersCards}
              let2OwnSummary={let2OwnSummary}
              allPeakDatesData={allPeakDatesData}
            />
          )}

          {letWithHavenEnabled &&
            [Panels.LETTING_AD, Panels.WAITLIST_AD].includes(currentPanel) && (
              <div className={styles.bookingContainer}>
                <BreakTransitions
                  breaks={selectedBreaks}
                  handleCancel={handleCloseActionBar}
                  handleLetWithHaven={onLetWithHavenSuccess}
                  handleSetError={setError}
                  handleSetInfo={setInfo}
                  handleSetAdvancedInfo={setAdvancedInfo}
                  type={currentPanel}
                  let2OwnSummary={let2OwnSummary}
                  allPeakDatesData={allPeakDatesData}
                />
              </div>
            )}
        </div>

        {currentPanel === Panels.INDEX && (
          <div className={styles.summaryActions}>
            <div ref={bookButtonRef}>
              <Button onClick={() => handleBookClick()}>
                <img
                  className={styles.addBookingIcon}
                  src="/assets/plus_white.svg"
                  alt=""
                  role="presentation"
                />
                Add booking
              </Button>
            </div>
            {renderBookTooltip()}
          </div>
        )}
      </div>
    </>
  );
};
