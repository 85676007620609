import React, { FunctionComponent } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import clsx from 'clsx';

import styles from './PlayPassNoteDialog.module.scss';

type Props = {
  isOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

export const OwnersCardNoteDialog: FunctionComponent<Props> = ({
  isOpen,
  handleOk,
  handleCancel,
}) => {
  return (
    <>
      {isOpen && (
        <div className={styles.root}>
          <div className={styles.dialogContent}>
            <Button
              variant="text"
              className={styles.closeButton}
              onClick={() => handleCancel()}
            >
              <img
                src="https://www.haven.com/assets/icons/close.svg"
                width={16}
                height={16}
                alt="close"
              />
            </Button>

            <img
              src="https://www.haven.com/assets/icons/info-circle-blue.svg"
              alt="info"
              width={64}
              height={64}
            />
            <h1 className={styles.title}>A note about your guests</h1>
            <p className={styles.text}>
              You will need to create guest cards for your organisation to
              enable your guests to enjoy facilities and activities on park.
              These can be left in your van for guests to use.
            </p>
            <p className={clsx(styles.text, styles.bold)}>
              Would you like to continue to create a card?
            </p>
            <div className={styles.buttonContainer}>
              <Button size="large" onClick={() => handleOk()}>
                Yes
              </Button>
              <Button
                size="large"
                variant="outlined"
                onClick={() => handleCancel()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
