import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import styles from './EventCalendarKey.module.scss';
import { LabelBadge } from './LabelBadge';
import { PanelHeader } from './PanelHeader';

type EventCalendarKeyProps = {
  onClose: () => void;
};

export const EventCalendarKey: FunctionComponent<EventCalendarKeyProps> = ({
  onClose,
}) => {
  // temporary - until confirmed Haven Let feature is available
  const confirmedHavenLetFeatureFlag = false;

  return (
    <div className={styles.keyPanel}>
      <PanelHeader title="Key:" handleCloseActionBar={onClose} />

      <div className={styles.keyPanelContent}>
        <div className={styles.today}>01</div>
        <div className={styles.gutterBottom}>Today</div>

        <div>
          02 <img src="/assets/icon_spanner_greyish_blue.svg" alt="" />
        </div>
        <div className={styles.gutterBottom}>Maintenance visit</div>

        <LabelBadge code={999} label={'you'} />
        <div className={styles.gutterBottom}>
          When you’re using your holiday home
        </div>

        <LabelBadge code={999} label={'johnston'} />
        <div className={styles.gutterBottom}>Private letting</div>

        <LabelBadge code={403} label={'park closed to guests'} />
        <div className={styles.gutterBottom}>Park closed</div>

        <div className={styles.peakContainer}>
          <div className={styles.peakValue}>28</div>
        </div>
        <div>Peak time</div>

        <div className={clsx([styles.peakContainer, styles.superPeak])}>
          <div className={styles.peakValue}>28</div>
        </div>
        <div>Super peak time</div>
      </div>

      <>
        <PanelHeader title="Letting with Haven:" withCloseIcon={false} />
        <div className={styles.keyPanelContent}>
          <LabelBadge code={4} value={'125.69'} label={''} />
          <div className={styles.gutterBottom}>
            Price you could get for Haven Fixed letting
          </div>
          <LabelBadge code={32} label={'on waitlist'} />
          <div className={styles.gutterBottom}>
            On waitlist to let with Haven
          </div>
          <LabelBadge code={1} label={'haven'} />
          <div className={styles.gutterBottom}>
            Accepted to let with Haven but no confirmed booking yet
          </div>
          {confirmedHavenLetFeatureFlag && (
            <>
              <LabelBadge code={1122} label={'haven'} />
              <div className={styles.gutterBottom}>Confirmed Haven booking</div>
            </>
          )}
        </div>
      </>
    </div>
  );
};
