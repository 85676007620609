import React from 'react';
import { useRouter } from 'next/router';
import {
  Let2OwnBreaksSummary,
  MODE,
} from '@havenengineering/module-owners-shared-library/dist/components/Let2OwnBreakSummary';
import useSWR from 'swr';

import { swrFetcher } from '../../../helpers/fetch';
import { putLet2OwnRenewal } from '../../../helpers/renewal';
import { DirectDebitBalanceType } from '../../../types/finance/directDebit/directDebit';
import { LettingConfigEntry } from '../../../types/lettingConfig';
import { Let2OwnSummary } from '../../helpers/bookings';
import { displayOneOffPayment } from '../../Let2OwnProgressStepper/helpers';

export interface Let2OwnBreakSummaryProps {
  isLoading: boolean;
  let2OwnSummary: Let2OwnSummary;
  currentDate: string;
  mode: MODE;
  skipPayment?: boolean;
  breaksNotSavedAlertEnabled?: boolean;
  accountId?: number;
  setLoading?: (value: boolean) => void;
}

export const Let2OwnBreaksSummaryWrapper: React.FC<
  Let2OwnBreakSummaryProps
> = ({
  isLoading,
  let2OwnSummary,
  currentDate,
  mode,
  skipPayment = false,
  breaksNotSavedAlertEnabled = false,
  accountId,
  setLoading,
}) => {
  const router = useRouter();

  const { data: config } = useSWR<LettingConfigEntry[]>(
    '/letting/letting-config?name=let2ownPartialPaymentDateRange',
    swrFetcher,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleRenewalClick = async () => {
    if (skipPayment && setLoading && accountId) {
      setLoading(true);
      await putLet2OwnRenewal(accountId);
      router.push('/lettings-hub?L2ORenewalsConfirmation=default');
    } else if (displayOneOffPayment(config, currentDate)) {
      router.push('/finance/partial-payment');
    } else {
      router.push(
        `/finance/direct-debit/setup?balanceType=${DirectDebitBalanceType.Let2Own}`
      );
    }
  };

  return (
    <Let2OwnBreaksSummary
      isLoading={isLoading}
      let2OwnSummary={let2OwnSummary}
      mode={mode}
      handleRenewalClick={handleRenewalClick}
      breaksNotSavedAlertEnabled={breaksNotSavedAlertEnabled}
    />
  );
};
