import React, {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';
import { Tooltip } from '@havenengineering/module-owners-shared-library/dist/components/Tooltip';

import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import {
  handleTooltipClick,
  handleTooltipDisplayed,
} from '../../helpers/googleTag';
import useOnboardingTooltips from '../../hooks/useOnboardingTooltips';
import { TooltipType } from '../../types/onboardingTooltip';

type Props = {
  tooltipType: TooltipType;
  handleDismiss?: () => void;
  parentRef?: MutableRefObject<any>;
  placement?: string;
};

export const TooltipFactory: FunctionComponent<Props> = ({
  tooltipType,
  handleDismiss,
  parentRef,
  placement,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const { tooltip } = useOnboardingTooltips(tooltipType);

  const tooltipReadableToGa =
    tooltip?.type?.toLowerCase()?.replace(/_/g, ' ') || '';

  useEffect(() => {
    setShowTooltip(!!tooltip);
  }, [tooltip]);

  const dismissTooltip = async (tooltipId: string, forever: boolean) => {
    try {
      await fetchWrapper(
        withApiBaseUrl(
          `/contentful/onboardingTooltips/${tooltipId}?dismiss=${forever}`
        ),
        {
          method: 'PUT',
          credentials: 'include',
        }
      );
    } catch (error) {
      // error during marking tooltip as seen
    }

    sessionStorage.setItem(tooltipId, 'tooltip_dismissed');
    handleDismiss && handleDismiss();
    handleTooltipClick(tooltipReadableToGa);
    setShowTooltip(false);
  };

  if (!tooltip || !showTooltip || !parentRef?.current) {
    return null;
  }

  handleTooltipDisplayed(tooltipReadableToGa);

  return (
    <Tooltip
      tooltip={tooltip}
      handleDismiss={dismissTooltip}
      parentRef={parentRef}
      placement={placement}
    />
  );
};
