import React, { FunctionComponent, useEffect } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import { TextInputField } from '@havenengineering/module-shared-library/dist/form/components/TextInput';
import useForm, {
  ukPhoneValidator,
} from '@havenengineering/module-shared-library/dist/form/hooks/useForm';

import styles from './ParticipantForm.module.scss';

interface ParticipantFormProps {
  participant?: any;
  handleSaveParticipant: (participant: any) => void;
  handleCancel: () => void;
}

const ParticipantForm: FunctionComponent<ParticipantFormProps> = ({
  participant = null,
  handleSaveParticipant,
  handleCancel,
}) => {
  const { fields, submit } = useForm<any>({
    initialState: {
      name: '',
      phoneNumber: '',
    },
    schema: {
      name: {
        required: true,
      },
      phoneNumber: {
        required: true,
        validator: ukPhoneValidator,
      },
    },
    submitCallback: async (state) => {
      handleSaveParticipant(state);
    },
  });

  useEffect(() => {
    fields.name.setValue(participant?.name || '');
    fields.phoneNumber.setValue(participant?.phoneNumber || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  return (
    <form
      onSubmit={(event) => event.preventDefault()}
      className={styles.formContainer}
      data-testid="participant-form"
    >
      <TextInputField
        className="fs-mask"
        id="guestName"
        label="Guest's name"
        field={fields.name}
        type="text"
      />
      <TextInputField
        className="fs-mask"
        guest-phone
        id="guestPhone"
        label="Guest's contact number"
        field={fields.phoneNumber}
        type="text"
      />
      <Button type="button" variant="outlined" onClick={() => handleCancel()}>
        Cancel
      </Button>
      <Button type="submit" onClick={() => submit()}>
        {participant ? 'Edit' : 'Add'} Participant
      </Button>
    </form>
  );
};

export default ParticipantForm;
