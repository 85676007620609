import * as React from 'react';
import { getTrackBackground, Range } from 'react-range';

import styles from './BreakSlider.module.scss';

interface BreakSliderProps {
  max: number;
  current: number;
  onChange: (breaks: number) => void;
}

export const BreakSlider = ({ max, current, onChange }: BreakSliderProps) => {
  const [values, setValues] = React.useState([max]);

  const handleChange = (values: number[]) => {
    setValues(values);
    onChange(values[0]);
  };
  return (
    <div className={styles.breakSlider}>
      <Range
        label="Select your value"
        step={1}
        min={0}
        max={max}
        values={values}
        onChange={(values) => handleChange(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '12px',
              borderRadius: '20px',
              background: getTrackBackground({
                values,
                colors: ['#0076B8', '#e5f1f8'],
                min: 0,
                max: max,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              backgroundColor: '#0076B8',
              boxShadow: 'none',
              borderRadius: '50%',
              border: '3px solid white',
              height: '24px',
              width: '24px',
              outline: 'none',
            }}
          >
            <span className={styles.handleLabel}>{current} Breaks</span>
          </div>
        )}
      />
    </div>
  );
};
