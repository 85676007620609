import React, { FunctionComponent } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import clsx from 'clsx';

import styles from './Stepper.module.scss';

type StepperProps = {
  handleNext?: () => void;
  handlePrev: () => void;
  nextIsDisabled: boolean;
  prevIsDisabled: boolean;
  dotsCount: number;
  activeDot: number;
  nextLabel?: string;
};

export const Stepper: FunctionComponent<StepperProps> = ({
  handleNext,
  handlePrev,
  nextIsDisabled,
  prevIsDisabled,
  dotsCount,
  activeDot,
  nextLabel = 'Next',
}) => {
  return (
    <div className={styles.stepperContainer}>
      <Button variant="outlined" onClick={handlePrev} disabled={prevIsDisabled}>
        Prev
      </Button>
      {dotsCount > 0 && (
        <div className={styles.dotsContainer}>
          {Array(dotsCount)
            .fill(null)
            .map((_, i) => (
              <div
                key={`${i}-dot`}
                className={clsx(styles.dot, {
                  [styles.activeDot]: i === activeDot,
                })}
              />
            ))}
        </div>
      )}
      <Button
        variant="contained"
        onClick={handleNext}
        disabled={nextIsDisabled}
      >
        {nextLabel}
      </Button>
    </div>
  );
};
