import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import style from './NoticeMessage.module.scss';

interface NoticeMessageProps {
  children: JSX.Element[] | JSX.Element;
  type?: MessagingTheme;
  desktopWidthPercentage?: number;
}

export const getIconBaseUrl = (filename: string) =>
  `https://www.haven.com/assets/icons/${filename}`;

export enum MessagingTheme {
  SUCCESS_THEME = 'success',
  ERROR_THEME = 'error',
  INFO_THEME = 'info',
  WARNING_THEME = 'warning',
  HELP_THEME = 'help',
}

const getTypeStyle = (type: string | undefined) => {
  switch (type) {
    case MessagingTheme.INFO_THEME:
    default:
      return {
        icon: getIconBaseUrl('inform-alert.svg'),
        className: MessagingTheme.INFO_THEME,
      };
    case MessagingTheme.SUCCESS_THEME:
      return {
        icon: getIconBaseUrl('check-grey.svg'),
        className: MessagingTheme.SUCCESS_THEME,
      };
    case MessagingTheme.ERROR_THEME:
      return {
        icon: getIconBaseUrl('close-cricle.svg'),
        className: MessagingTheme.ERROR_THEME,
      };
    case MessagingTheme.HELP_THEME:
      return {
        icon: getIconBaseUrl('questionmark-grey.svg'),
        className: MessagingTheme.HELP_THEME,
      };
    case MessagingTheme.WARNING_THEME:
      return {
        icon: getIconBaseUrl('warning-circle.svg'),
        className: MessagingTheme.WARNING_THEME,
      };
  }
};

export const NoticeMessage: FunctionComponent<NoticeMessageProps> = (
  props: NoticeMessageProps
) => {
  const { children, type, desktopWidthPercentage } = props;
  const typeStyle = getTypeStyle(type);
  return (
    <div
      style={{ width: `${desktopWidthPercentage}%` }}
      className={clsx(style.container, style[typeStyle.className])}
    >
      <img className={style.icon} src={typeStyle.icon} alt={type} />
      <div className={style.content}>{children}</div>
    </div>
  );
};
