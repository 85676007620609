import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { ConfirmationOverlay } from '@havenengineering/module-owners-shared-library/dist/components/ConfirmationOverlay/ConfirmationOverlay';
import {
  DateSelection,
  EventCalendar,
  EventData,
} from '@havenengineering/module-owners-shared-library/dist/components/EventCalendar';
import { PillTooltip } from '@havenengineering/module-owners-shared-library/dist/components/Tooltip';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';
import { getEventIds } from '@havenengineering/module-owners-shared-library/dist/utils';
import {
  Alignment,
  HorizontalTabs,
} from '@havenengineering/module-shared-library/dist/components/HorizontalTabs';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator/LoadingIndicator';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import useSWR from 'swr';

import BookingList from '../../components/BookingList/BookingList';
import { BreakDataCoreStatus } from '../../components/EventCalendarApendix/Booking';
import { CalendarBanner } from '../../components/EventCalendarApendix/CalendarBanner/CalendarBanner';
import { CalendarPanel } from '../../components/EventCalendarApendix/CalendarPanel';
import { EventCalendarKey } from '../../components/EventCalendarApendix/EventCalendarKey';
import {
  InfoPanel,
  InfoSelection,
} from '../../components/EventCalendarApendix/InfoPanel';
import { InlineMessage } from '../../components/EventCalendarApendix/InlineMessage';
import { OwnersCardNoteDialog } from '../../components/EventCalendarApendix/OwnersCardNoteDialog';
import { Let2OwnSummary } from '../../components/helpers/bookings';
import {
  Let2OwnProgressStepper,
  Let2OwnRenewalSteps,
} from '../../components/Let2OwnProgressStepper/Let2OwnProgressStepper';
import { TooltipFactory } from '../../components/TooltipFactory/TooltipFactory';
import { LettingsContext } from '../../contexts/lettings';
import {
  getArrivalEventData,
  getBreakEventData,
  getParkClosedEventData,
  getParkExEventData,
  getPeakDatesEventData,
  isEligibleToLetWithHaven,
  isEligibleToLetWithHavenNextSeason,
  isLetWithHaven,
  isOffer,
  isUnavailable,
  isWaitList,
} from '../../helpers/calendar';
import { now } from '../../helpers/dateTime';
import {
  fetchWrapper,
  swrFetcher,
  withApiBaseUrl,
  withArrivalsApiBaseUrl,
  withEagleEyeApiBaseUrl,
} from '../../helpers/fetch';
import {
  buttonClickGTMEvent,
  handleArrivalBookingOnCalendarGTM,
  handleCalendarListViewChange,
  handleDateSelectOnCalendar,
  pageView,
} from '../../helpers/googleTag';
import { accountIsValidForLetting } from '../../helpers/lettingMarketing';
import { useLettingAdverts } from '../../hooks/useLettingAdverts';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import styles from '../../styles/pages/bookings.module.scss';
import { TooltipType } from '../../types/onboardingTooltip';

const {
  publicRuntimeConfig: { PUBLIC_APP_URL },
} = getConfig();

export enum ParkClosedDataTypeEnum {
  PARK_CLOSED = 403,
}

export enum ArrivalDataEnum {
  ARRIVAL_BOOKING = 999,
}

export enum BreakDataAction {
  LET = 'LET',
  REMOVE_LET = 'REMOVE_LET',
}

export enum Panels {
  INDEX = 'index',
  ARRIVAL_EDIT = 'arrivalEdit',
  LET_WITH_HAVEN_EDIT = 'letWithHavenEdit',
  BOOKING = 'booking',
  LET_WITH_HAVEN = 'letWithHaven',
  WAITLIST_AD = 'waitlistAd',
  LETTING_AD = 'lettingAd',
  LET_SUCCESS = 'letSuccess',
  LET_PARTIAL_SUCCESS = 'letPartialSuccess',
}

export enum StyleTypes {
  DASHED = 'dashed',
  DAY_STYLES_ONY = 'dayStylesOnly',
  IMAGE = 'image',
  NORMAL = 'normal',
  PART_EX = 'partEx',
}

export enum BookingTab {
  CALENDAR = 0,
  LIST = 1,
}

export enum BreakEventStatus {
  FREE = 'free',
  HAVEN = 'haven',
  WAITLIST = 'waitlist',
  PRIVATE_LET = 'private let',
  OWNER = 'owner',
}

export interface EventDateSelection {
  startDate: DateTime;
  endDate: DateTime;
}

export interface EventSelection {
  dates: EventDateSelection | null;
  events?: EventData[];
}

export type BreakSummary = {
  breakCount: number;
  values: number;
  flexiValues?: { min: number; max: number };
};

export type MonthlyBreakSummary = Record<string, BreakSummary>;

const Bookings = ({ currentDate }: { currentDate: string }) => {
  const router = useRouter();

  const defaultTab =
    router?.query?.view === 'list' ? BookingTab.LIST : BookingTab.CALENDAR;

  const { loggedInUser, activeAccount, gtmPageViewAuthInfo } = useAuthContext();

  const { isLet2OwnRenewal } = useContext(LettingsContext);

  const { waitlist: waitlistLettingAds, nonWaitlist: nonWaitlistLettingAds } =
    useLettingAdverts(activeAccount?.accountID, { getSeparateWaitlist: true });

  const [arrivalBookings, setArrivalBookings] = useState<ArrivalBookingState>({
    upcoming: [],
    previous: [],
  });
  const [monthlyBreakSummary, setMonthlyBreakSummary] =
    useState<MonthlyBreakSummary>();
  const [breakData, setBreakData] = useState<BreakDataResponse[]>([]);
  const [let2OwnSummary, setLet2OwnSummary] = useState<Let2OwnSummary>();
  const [parkClosedData, setParkClosedData] = useState<ParkClosedData[]>([]);
  const [allPeakDatesData, setAllPeakDatesData] = useState<PeakDatesData[]>([]);
  const [peakDatesData, setPeakDatesData] = useState<PeakDatesData[]>([]);
  const [superPeakDatesData, setSuperPeakDatesData] = useState<PeakDatesData[]>(
    []
  );
  const [tabView, setTabView] = useState<BookingTab>(defaultTab);
  const mobileView = useScreenWidth(960);
  const [error, setError] = useState(false);
  const [panelError, setPanelError] = useState('');
  const [panelInfo, setPanelInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [calendarSelection, setCalendarSelection] =
    useState<EventSelection | null>(null);
  const [lettingDateAdSelection, setLettingDateAdSelection] =
    useState<EventSelection | null>(null);
  const [addEventSelection, setAddEventSelection] =
    useState<EventSelection | null>(null);
  const [lettingDateAdPanel, setLettingDateAdPanel] = useState<
    Panels | undefined
  >(undefined);
  const [activeLettingDateAd, setActiveLettingDateAd] = useState<
    Advert | undefined
  >();
  const [maximumVanLettingAge, setMaximumVanLettingAge] = useState<
    number | undefined
  >();
  const [activeSeasonForLetting, setActiveSeasonForLetting] = useState<number>(
    now().year
  );
  const [initialPanel, setInitialPanel] = useState<Panels>(Panels.INDEX);
  const [allowedToLet, setAllowedtoLet] = useState(false);
  const [allowedToLetNextSeason, setAllowedToLetNextSeason] = useState(false);
  const [fromAddEvent, setFromAddEvent] = useState(false);
  const [isAccountValidForLetting, setIsAccountValidForLetting] =
    useState<boolean>(false);
  const [parkAllowsLetting, setParkAllowsLetting] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [hasSubmittedApplication, setHasSubmittedApplication] = useState(false);
  const [firstEventStatus, setFirstEventStatus] = useState<BreakEventStatus>(
    BreakEventStatus.FREE
  );
  const [arrivalBookingToEdit, setArrivalBookingToEdit] =
    useState<ArrivalBooking>();
  const [letWithHavenBookingToEdit, setLetWithHavenBookingToEdit] =
    useState<BreakDataResponse>();
  const [showNextSeasonDialog, setShowNextSeasonDialog] = useState(false);
  const [dismissedForSession, setDismissedForSession] = useState(false);
  const [ownersCards, setOwnersCards] = useState<OwnersCardResponse | null>(
    null
  );
  const [ownersCardDialogIsOpen, setOwnersCardDialogIsOpen] = useState(false);
  const [skipRenewalPayment, setSkipRenewalPayment] = useState<boolean>(false);
  const [initialBalanceLoaded, setInitialBalanceLoaded] =
    useState<boolean>(false);
  const [showScrollTooltip, setShowScrollTooltip] = useState<boolean>(false);
  const [breaksAlreadyAdded, setBreaksAlreadyAdded] = useState<boolean>(false);

  const { data: needsToBeShown } = useSWR(
    `/letting/show-letting-renewal-dialog/${activeAccount?.accountID}`,
    swrFetcher
  );
  const lettingRenewalStatus = useMemo(
    () => !!needsToBeShown && !dismissedForSession,
    [needsToBeShown, dismissedForSession]
  );

  const arrivals = useMemo(
    () =>
      getArrivalEventData(
        [...arrivalBookings.upcoming, ...arrivalBookings.previous].filter(
          (booking) => !booking.isCancelled
        ),
        loggedInUser?.name
      ),
    [arrivalBookings.previous, arrivalBookings.upcoming, loggedInUser?.name]
  );

  const eventData = useMemo(() => {
    const eventfulArrivalBookings = getArrivalEventData(
      [...arrivalBookings.upcoming, ...arrivalBookings.previous].filter(
        (booking) => !booking.isCancelled
      ),
      loggedInUser?.name
    );
    const { breakEventData, partExEventData } = getBreakEventData(
      breakData,
      allowedToLet
    );
    const eventfulParkClosedData = getParkClosedEventData(parkClosedData);
    const eventfulPeakDateData = getPeakDatesEventData(peakDatesData);
    const eventfulPartExData = getParkExEventData(partExEventData);

    const isSuperPeak = true;
    const eventfulSuperPeakDateData = getPeakDatesEventData(
      superPeakDatesData,
      isSuperPeak,
      mobileView
    );

    return [
      ...eventfulArrivalBookings,
      ...breakEventData,
      ...eventfulParkClosedData,
      ...eventfulPeakDateData,
      ...eventfulSuperPeakDateData,
      ...eventfulPartExData,
    ];
  }, [
    arrivalBookings.previous,
    arrivalBookings.upcoming,
    breakData,
    parkClosedData,
    allowedToLet,
    peakDatesData,
    superPeakDatesData,
    loggedInUser,
    mobileView,
  ]);

  const accountNo = activeAccount?.accountNo as string;

  const plusIconRef = useRef<HTMLDivElement>(null);

  const infoSelection =
    addEventSelection || calendarSelection || lettingDateAdSelection;

  const isParkClosed = !!infoSelection?.events?.some(
    (event) => event.code === ParkClosedDataTypeEnum.PARK_CLOSED
  );

  const getArrivalBookings = async () => {
    try {
      const response = await fetchWrapper(
        withArrivalsApiBaseUrl(`/user/${accountNo}/booking`),
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      setArrivalBookings(response);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const getBreakData = async () => {
    try {
      const startDate = now()
        .minus({ years: 1 })
        .startOf('year')
        .toFormat('yyyy-MM-dd');
      const endDate = now().plus({ years: 2 }).toFormat('yyyy-MM-dd');

      const response = (await fetchWrapper(
        withApiBaseUrl(
          `/plot/${activeAccount?.accountID}/lettingbreaks?startDate=${startDate}&endDate=${endDate}`
        ),
        {
          method: 'GET',
          credentials: 'include',
        }
      )) as {
        breaks: BreakDataResponse[];
        monthlyBreakSummary: MonthlyBreakSummary;
      };

      const isBreaksAdded = response.breaks?.some(
        (b) =>
          (b.status as BreakDataStatusWithCoreStatus).coreStatus ===
            BreakDataCoreStatus.LET && !b.status.outOfSeason
      );
      setBreaksAlreadyAdded(isBreaksAdded);
      setBreakData(response.breaks);
      setMonthlyBreakSummary(
        Object.keys(response.monthlyBreakSummary).length > 0
          ? response.monthlyBreakSummary
          : undefined
      );
    } catch (error) {
      console.error(error);
      setBreakData([]);
    }
  };

  const getLet2OwnSummary = async () => {
    try {
      const response = (await fetchWrapper(
        withApiBaseUrl(
          `/letting/let2own-break-stats/${activeAccount?.accountID}`
        ),
        {
          method: 'GET',
          credentials: 'include',
        }
      )) as Let2OwnSummary;

      setLet2OwnSummary(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getLet2OwnBalance = useCallback(async () => {
    if (isLet2OwnRenewal) {
      try {
        const response = await fetchWrapper(
          withApiBaseUrl(
            `/account/direct-debit/let2own-balance?accountId=${activeAccount?.accountID}`
          ),
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        if (response.balance === null || response.balance === undefined) {
          setSkipRenewalPayment(false);
        } else {
          setSkipRenewalPayment(response.balance <= 0);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [activeAccount?.accountID, isLet2OwnRenewal]);

  const refetchEventData = async (closeActionBar?: boolean) => {
    setError(false);
    setLoading(true);

    await Promise.allSettled([
      getBreakData(),
      getArrivalBookings(),
      getLet2OwnSummary(),
      getLet2OwnBalance(),
    ]).finally(() => {
      setLoading(false);

      if (closeActionBar) {
        handleCloseActionBar();
      }
    });
  };

  const getParkClosedData = async () => {
    try {
      const response = (await fetchWrapper(
        withApiBaseUrl(`/parks/${activeAccount?.parkCode}/closedDates`),
        {
          method: 'GET',
        }
      )) as ParkClosedData | ParkClosedData[];

      response &&
        setParkClosedData(Array.isArray(response) ? response : [response]);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const getPeakDatesData = async () => {
    try {
      const response: PeakDatesResponse = await fetchWrapper(
        withApiBaseUrl(
          `/parks/${activeAccount?.parkCode}/peakDates?accountId=${activeAccount?.accountID}`
        ),
        { method: 'GET', credentials: 'include' }
      );

      const peakDates =
        response?.peak.map((date) => ({
          ...date,
          startDate: DateTime.fromISO(date.startDate)
            .startOf('day')
            .toISODate(),
          endDate: DateTime.fromISO(date.endDate).startOf('day').toISODate(),
        })) || [];

      const superPeakDates =
        response?.superPeak.map((date) => ({
          ...date,
          startDate: DateTime.fromISO(date.startDate)
            .startOf('day')
            .toISODate(),
          endDate: DateTime.fromISO(date.endDate).startOf('day').toISODate(),
        })) || [];

      setAllPeakDatesData([...peakDates, ...superPeakDates]);
      setPeakDatesData(peakDates);
      setSuperPeakDatesData(superPeakDates);
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const getMaximumVanLettingAge = async () => {
    try {
      const value = await fetchWrapper(
        withApiBaseUrl(`/plot/${activeAccount?.accountID}/van-eligibility`),
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      setMaximumVanLettingAge(value);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveSeasonForLetting = async () => {
    try {
      const value = await fetchWrapper(withApiBaseUrl('/plot/active-season'), {
        method: 'GET',
        credentials: 'include',
      });
      setActiveSeasonForLetting(value);
    } catch (error) {
      console.error(error);
    }
  };

  const getParkLettingStatus = async () => {
    try {
      const value = await fetchWrapper(
        withApiBaseUrl(`/plot/${activeAccount?.accountID}/park-eligibility`),
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      setParkAllowsLetting(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (fromAddEvent) {
      setInitialPanel(Panels.BOOKING);
    }
    if (tabView === BookingTab.LIST && arrivalBookingToEdit) {
      setInitialPanel(Panels.ARRIVAL_EDIT);
    }
    if (tabView === BookingTab.LIST && letWithHavenBookingToEdit) {
      setInitialPanel(Panels.LET_WITH_HAVEN_EDIT);
    }
    if (lettingDateAdPanel) {
      setLettingDateAdPanel(lettingDateAdPanel);
    }
  }, [
    fromAddEvent,
    lettingDateAdPanel,
    tabView,
    arrivalBookingToEdit,
    letWithHavenBookingToEdit,
  ]);

  useEffect(() => {
    pageView(
      'bookings',
      tabView
        ? '/ga-virtual/owners-arrivals/bookings/list-view'
        : '/ga-virtual/owners-arrivals/bookings/calendar-view',
      'owners arrivals',
      gtmPageViewAuthInfo
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabView]);

  const fetchCards = async (accountNo: string) => {
    try {
      const response = await fetchWrapper(
        withEagleEyeApiBaseUrl(`/owner-card/list?accountNumber=${accountNo}`),
        {
          method: 'GET',
          credentials: 'include',
          headers: { 'x-account-number': accountNo } as Record<string, string>,
        }
      );
      setOwnersCards(response);
      if (response?.accountIsCharity && response?.charities.length === 0) {
        setOwnersCardDialogIsOpen(true);
      }
    } catch (error) {
      setOwnersCards(null);
    }
  };

  useEffect(() => {
    setError(false);
    setLoading(true);
    setCalendarSelection(null),
      Promise.allSettled([
        getArrivalBookings(),
        getLet2OwnSummary(),
        getParkClosedData(),
        getPeakDatesData(),
        getMaximumVanLettingAge(),
        getParkLettingStatus(),
        getActiveSeasonForLetting(),
        fetchCards(accountNo),
        getLet2OwnBalance(),
        activeAccount &&
          accountIsValidForLetting(activeAccount).then(
            ({ isValid, hasSubmitted }) => {
              setIsAccountValidForLetting(isValid);
              setHasSubmittedApplication(hasSubmitted);
              if (isValid) {
                return getBreakData();
              } else {
                setBreakData([]);
              }
            }
          ),
      ]).finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNo, router?.query?.from]);

  useEffect(() => {
    if (maximumVanLettingAge && activeAccount) {
      setAllowedtoLet(
        isEligibleToLetWithHaven(activeAccount, maximumVanLettingAge)
      );
      setAllowedToLetNextSeason(
        isEligibleToLetWithHavenNextSeason(activeAccount, maximumVanLettingAge)
      );
    }
  }, [maximumVanLettingAge, activeAccount]);

  const resetCalendarState = (resetState: {
    calendarState?: boolean;
    addEventState?: boolean;
    adSelection?: boolean;
    key?: boolean;
    initPanel?: boolean;
    arrivalBookingToEdit?: boolean;
    letWithHavenBookingToEdit?: boolean;
  }) => {
    if (resetState.calendarState) {
      setCalendarSelection(null);
    }
    if (resetState.addEventState) {
      setAddEventSelection(null);
      setFromAddEvent(false);
    }
    if (resetState.adSelection) {
      setLettingDateAdSelection(null);
      setLettingDateAdPanel(undefined);
    }
    if (resetState.key) {
      setShowKey(false);
    }
    if (resetState.initPanel) {
      setInitialPanel(Panels.INDEX);
    }
    if (resetState.arrivalBookingToEdit) {
      setArrivalBookingToEdit(undefined);
    }
    if (resetState.letWithHavenBookingToEdit) {
      setLetWithHavenBookingToEdit(undefined);
    }
  };

  const handleCloseActionBar = () => {
    resetCalendarState({
      calendarState: true,
      addEventState: true,
      adSelection: true,
      key: true,
      initPanel: true,
      arrivalBookingToEdit: true,
      letWithHavenBookingToEdit: true,
    });
    if (router?.query?.from) {
      router.push('/bookings');
    }
  };

  const handleAddEventClick = () => {
    resetCalendarState({
      adSelection: true,
      key: true,
      initPanel: true,
    });
    setAddEventSelection(null);
    setFromAddEvent(true);

    const selection = {
      startDate:
        calendarSelection?.dates?.startDate ?? DateTime.now().startOf('day'),
      endDate:
        calendarSelection?.dates?.endDate ?? DateTime.now().startOf('day'),
    };

    setAddEventSelection(
      constructNewSelection(
        selection.startDate as DateTime,
        selection.endDate as DateTime,
        new Set<string>()
      )
    );
  };

  const handleKeyClick = () => {
    resetCalendarState({
      calendarState: true,
      addEventState: true,
      adSelection: true,
      initPanel: true,
    });
    setShowKey(true);
    buttonClickGTMEvent({
      interactionName: 'arrival calendar view key',
    });
  };

  useEffect(() => {
    const getInitialLet2OwnBalance = () => {
      if (isLet2OwnRenewal) {
        Promise.resolve(getLet2OwnBalance()).finally(() =>
          setInitialBalanceLoaded(true)
        );
      } else {
        setInitialBalanceLoaded(true);
      }
    };
    getInitialLet2OwnBalance();
  }, [isLet2OwnRenewal, getLet2OwnBalance]);

  const constructNewSelection = useCallback(
    (startDate: DateTime, endDate: DateTime, eventIds: Set<string> | null) => {
      // gtm
      const events = eventIds
        ? eventData.filter(
            (data) =>
              eventIds.has(data.id) &&
              !isOffer(data.code) &&
              !isUnavailable(data.code)
          )
        : [];

      const bookingEvents = events?.filter(
        (e) => e.styleType !== StyleTypes.DAY_STYLES_ONY
      );

      const firstEvent = bookingEvents?.sort(
        (a: EventData, b: EventData) =>
          DateTime.fromISO(a.data.startDate || a.data.dateArrival).toMillis() -
          DateTime.fromISO(b.data.startDate || b.data.dateArrival).toMillis()
      )?.[0];

      const breakStatus = () => {
        if (firstEvent?.code) {
          if (isLetWithHaven(firstEvent.code)) {
            return BreakEventStatus.HAVEN;
          }

          if (isWaitList(firstEvent.code)) {
            return BreakEventStatus.WAITLIST;
          }

          if (firstEvent.code === 999) {
            return (firstEvent as EventData<ArrivalBooking>).data?.isLetting
              ? BreakEventStatus.PRIVATE_LET
              : BreakEventStatus.OWNER;
          }
        }
        return BreakEventStatus.FREE;
      };

      const eventStatus = breakStatus();
      setFirstEventStatus(eventStatus);

      handleDateSelectOnCalendar(
        startDate.toFormat('yyyy-MM-dd'),
        eventStatus,
        endDate.toFormat('yyyy-MM-dd')
      );

      return {
        dates: {
          startDate,
          endDate,
        },
        // pricing event type should be excluded from selection
        events: bookingEvents,
      };
    },
    [eventData]
  );

  const onDateSelect = useCallback(
    (selection: DateSelection, eventIds: Set<string> | null) => {
      resetCalendarState({
        addEventState: true,
        adSelection: true,
        key: true,
        initPanel: true,
      });
      const { startDate, endDate } = selection;

      if (startDate && endDate) {
        setCalendarSelection(
          constructNewSelection(startDate, endDate, eventIds)
        );
      }
    },
    [constructNewSelection]
  );

  const [
    initialAutomaticSelectionHappened,
    setInitialAutomaticSelectionHappened,
  ] = useState(false);

  useEffect(() => {
    setLettingDateAdPanel((router?.query?.from as Panels) || undefined);

    const activeAd =
      router?.query?.from === 'lettingAd'
        ? nonWaitlistLettingAds[0]
        : router?.query?.from === 'waitlistAd'
        ? waitlistLettingAds[0]
        : null;

    if (activeAd && !initialAutomaticSelectionHappened) {
      setInitialAutomaticSelectionHappened(true);

      setActiveLettingDateAd(activeAd);
      setLettingDateAdSelection({
        dates: {
          startDate: DateTime.fromISO(activeAd.startDate).startOf('day'),
          endDate: DateTime.fromISO(activeAd.endDate).startOf('day'),
        },
      });

      onDateSelect(
        {
          startDate: DateTime.fromISO(activeAd.startDate).startOf('day'),
          endDate: DateTime.fromISO(activeAd.endDate).startOf('day'),
        },
        getEventIds(
          {
            startDate: DateTime.fromISO(activeAd.startDate).startOf('day'),
            endDate: DateTime.fromISO(activeAd.endDate).startOf('day'),
          },
          eventData
        )
      );

      setInitialPanel((router?.query?.from as Panels) || undefined);
    }
  }, [
    initialAutomaticSelectionHappened,
    eventData,
    nonWaitlistLettingAds,
    router?.query?.from,
    waitlistLettingAds,
    onDateSelect,
  ]);

  useEffect(() => {
    setTimeout(() => setShowScrollTooltip(true), 500);
  }, []);

  const onCalendarScroll = () => {
    if (showScrollTooltip) setShowScrollTooltip(false);
  };

  const onSelectionDateChange = (newSelection: EventDateSelection) => {
    const { startDate, endDate } = newSelection;
    const selectedEventIds = getEventIds(newSelection, eventData);

    setAddEventSelection(
      constructNewSelection(startDate, endDate, selectedEventIds)
    );
    setCalendarSelection(
      constructNewSelection(startDate, endDate, selectedEventIds)
    );
  };

  const handleArrivalEdit = (editedBooking: ArrivalBooking, tagging = true) => {
    setArrivalBookings((prev) => ({
      previous: [...prev.previous],
      upcoming: [
        ...prev.upcoming.filter((b) => b.shortCode !== editedBooking.shortCode),
        {
          ...prev.upcoming.find((b) => b.shortCode === editedBooking.shortCode),
          ...editedBooking,
        },
      ],
    }));

    // gtm
    const editedVersion = {
      ...arrivalBookings.upcoming.find(
        (b) => b.shortCode === editedBooking.shortCode
      ),
      ...editedBooking,
    };

    if (tagging) {
      handleArrivalBookingOnCalendarGTM(editedVersion, 'edit arrival');
    }
  };

  const handleLetWithHavenEdit = async (selectedBreak: {
    startDate: string;
  }) => {
    await refetchEventData();
    setCalendarSelection((selection) => ({
      dates: selection?.dates ?? null,
      events: selection?.events?.filter(
        (e) =>
          (e.data as BreakDataResponse).startDate !== selectedBreak?.startDate
      ),
    }));
    resetCalendarState({
      initPanel: true,
      letWithHavenBookingToEdit: true,
    });
  };

  const handleArrivalBooking = async () => getArrivalBookings();
  const handleLetWithHaven = async (outOfSeason: boolean) => {
    refetchEventData();
    setShowNextSeasonDialog(outOfSeason);
  };

  const handleTabViewChange = (tab: BookingTab) => {
    resetCalendarState({
      calendarState: true,
      addEventState: true,
      adSelection: true,
      key: true,
      initPanel: true,
    });
    handleCalendarListViewChange(tab);
    setTabView(tab);
  };
  // do we wanna block the whole calendar if there is an error?
  if (error) {
    return (
      <InlineMessaging
        type={InlineMessagingTheme.ERROR_THEME}
        title="Error"
        message="Something went wrong, please try again"
        dismissible={false}
      />
    );
  }

  const navigateToAutoRenewalForm = () => {
    handleNextSeasonDialogSeen();
    router.push('/lettings-hub/letting-application/renewal');
  };
  const handleNextSeasonDialogCancel = () => {
    handleNextSeasonDialogSeen();
  };
  const handleNextSeasonDialogSeen = async () => {
    try {
      await fetchWrapper(
        withApiBaseUrl(
          `/letting/show-letting-renewal-dialog/${activeAccount?.accountID}`
        ),
        {
          method: 'PUT',
          credentials: 'include',
        }
      );
    } catch (error) {
      // error during marking letting renewal dialog as seen
    }
    setDismissedForSession(true);
    setShowNextSeasonDialog(false);
  };

  const onArrivalBookingListEdit = (
    selection: DateSelection,
    eventIds: Set<string> | null,
    data: ArrivalBooking
  ) => {
    onDateSelect(selection, eventIds);
    setArrivalBookingToEdit(data);
  };

  const onLetWithHavenBookingListEdit = (
    selection: DateSelection,
    eventIds: Set<string> | null,
    data: BreakDataResponse
  ) => {
    onDateSelect(selection, eventIds);
    setLetWithHavenBookingToEdit(data);
    setInitialPanel(Panels.LET_WITH_HAVEN_EDIT);
  };

  return (
    <div>
      <OwnersCardNoteDialog
        isOpen={ownersCardDialogIsOpen}
        handleOk={() => router.push(`${PUBLIC_APP_URL}/owners-card`)}
        handleCancel={() => setOwnersCardDialogIsOpen(false)}
      />
      <ConfirmationOverlay
        isOpen={
          allowedToLetNextSeason && lettingRenewalStatus && showNextSeasonDialog
        }
        title="Continue letting with Haven"
        content={
          <>
            <p>
              You’ve selected a date to let with Haven in the next season,
              please confirm you’d like to let with Haven to continue.
            </p>
          </>
        }
        handleOk={navigateToAutoRenewalForm}
        handleCancel={handleNextSeasonDialogCancel}
        okLabel="See what’s new"
        cancelLabel="Cancel"
      />
      {isLet2OwnRenewal && initialBalanceLoaded && (
        <Let2OwnProgressStepper
          currentStep={Let2OwnRenewalSteps.ADD_BREAKS}
          currentDate={currentDate}
          skipPayment={skipRenewalPayment}
        />
      )}
      <h1 className="owners-heading-1">
        {isLet2OwnRenewal ? 'Add your breaks' : 'Manage bookings'}
      </h1>
      {router?.query?.success === 'true' && (
        <div className={styles.inlineMessageContainer}>
          <InlineMessaging
            type={InlineMessagingTheme.SUCCESS_THEME}
            message={"Success, we've received your letting dates!"}
            dismissible={true}
          />
        </div>
      )}
      {router?.query?.success === 'false' && (
        <div className={styles.inlineMessageContainer}>
          <InlineMessaging
            type={InlineMessagingTheme.ERROR_THEME}
            message={
              "We're sorry there's been a problem adding your letting dates, please try again later."
            }
            dismissible={true}
          />
        </div>
      )}
      <CalendarBanner
        accountId={activeAccount?.accountID}
        setLoading={setLoading}
        loading={loading || !initialBalanceLoaded}
        let2OwnSummary={let2OwnSummary}
        skipPayment={skipRenewalPayment}
        breaksNotSavedAlertEnabled={breaksAlreadyAdded}
      />
      <HorizontalTabs
        className={styles.tab}
        tabs={[
          { id: BookingTab.CALENDAR, label: 'Calendar view' },
          { id: BookingTab.LIST, label: 'List view' },
        ]}
        alignment={Alignment.LEFT}
        defaultTabId={defaultTab}
        onClick={(id) => handleTabViewChange(id)}
      />
      <div
        className={clsx(
          styles.calendarGrid,
          mobileView && styles.stretchParentPadding
        )}
      >
        {panelError || panelInfo ? (
          <div className={mobileView ? styles.inlineMessageContainer : ''}>
            {panelError && (
              <InlineMessage
                type="error"
                message={panelError}
                onClick={() => setPanelError('')}
              />
            )}
            {panelInfo && (
              <InlineMessage
                type="info"
                message={panelInfo}
                onClick={() => setPanelInfo('')}
              />
            )}
          </div>
        ) : null}

        <div className={styles.calendarContainer}>
          {loading || !initialBalanceLoaded ? (
            <div className={styles.calendarLoader}>
              <LoadingIndicator loading={true} />
            </div>
          ) : null}
          {router.isReady && tabView === BookingTab.CALENDAR && (
            <>
              <EventCalendar
                local // use local time until arrivals time zone issue is fixed: https://github.com/HavenEngineering/app-haven-owner-arrivals/issues/202
                dateSelection={
                  calendarSelection && calendarSelection.dates
                    ? {
                        startDate: calendarSelection.dates.startDate,
                        endDate: calendarSelection.dates.endDate,
                      }
                    : lettingDateAdSelection && lettingDateAdSelection.dates
                    ? {
                        startDate: lettingDateAdSelection.dates.startDate,
                        endDate: lettingDateAdSelection.dates.endDate,
                      }
                    : addEventSelection && addEventSelection.dates
                    ? {
                        startDate: addEventSelection.dates.startDate,
                        endDate: addEventSelection.dates.endDate,
                      }
                    : {
                        startDate: undefined,
                        endDate: undefined,
                      }
                }
                eventData={eventData}
                onSelect={onDateSelect}
                onAddEventClick={handleAddEventClick}
                plusIconRef={plusIconRef}
                onKeyClick={handleKeyClick}
                monthlySummary={monthlyBreakSummary}
                initialDate={router?.query?.initialDate as string}
                scrollToSelection={!!activeLettingDateAd}
                onScroll={onCalendarScroll}
              />
              <TooltipFactory
                tooltipType={TooltipType.CALENDAR_EVENT_ADD}
                parentRef={plusIconRef}
              />
            </>
          )}
          {tabView === BookingTab.LIST && (
            <BookingList
              eventData={eventData}
              onArrivalEdit={onArrivalBookingListEdit}
              onLetWithHavenEdit={onLetWithHavenBookingListEdit}
              onKeyClick={handleKeyClick}
              onAddEventClick={handleAddEventClick}
              refetchEventData={refetchEventData}
              mobileView={mobileView}
              allPeakDatesData={allPeakDatesData}
              let2OwnSummary={let2OwnSummary}
            />
          )}
          {((infoSelection && infoSelection.dates) || showKey) && (
            <CalendarPanel>
              {showKey && (
                <EventCalendarKey onClose={() => setShowKey(false)} />
              )}
              {infoSelection && infoSelection.dates && (
                <InfoPanel
                  handleCloseActionBar={handleCloseActionBar}
                  selection={infoSelection as InfoSelection}
                  arrivals={arrivals}
                  handleArrivalEdit={handleArrivalEdit}
                  handleArrivalBooking={handleArrivalBooking}
                  letWithHavenBookingToEdit={letWithHavenBookingToEdit}
                  handleLetWithHavenEdit={handleLetWithHavenEdit}
                  handleLetWithHaven={handleLetWithHaven}
                  initialPanel={initialPanel}
                  activeLettingAd={activeLettingDateAd}
                  fromAddEvent={fromAddEvent}
                  handleDateSelectionChange={onSelectionDateChange}
                  letWithHavenEnabled={isAccountValidForLetting}
                  isParkClosed={isParkClosed}
                  allowedToLet={allowedToLet}
                  parkAllowsLetting={parkAllowsLetting}
                  hasSubmittedApplication={hasSubmittedApplication}
                  firstEventStatus={firstEventStatus}
                  tabView={tabView}
                  mobileView={mobileView}
                  refetchEventData={refetchEventData}
                  setError={setPanelError}
                  setInfo={setPanelInfo}
                  activeSeason={activeSeasonForLetting}
                  ownersCards={ownersCards}
                  let2OwnSummary={let2OwnSummary}
                  allPeakDatesData={allPeakDatesData}
                  setCalendarLoading={setLoading}
                />
              )}
            </CalendarPanel>
          )}
        </div>
        <PillTooltip
          className={clsx(
            styles.scrollTooltip,
            showScrollTooltip && styles.showScrollTooltip
          )}
          text={'You can scroll to see more.'}
        />
      </div>
    </div>
  );
};

export const getStaticProps = () => ({
  props: {
    pageTitle: 'Bookings',
    currentDate: new Date().toISOString(),
  },
  revalidate: 60 * 30,
});

export default Bookings;
