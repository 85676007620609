export const getDateSuffix = (date: number) => {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const capitalizeFirstLetter = ([
  firstChar,
  ...remainingChars
]: string) => {
  return `${firstChar.toUpperCase()}${remainingChars.join('')}`;
};

export const proseListFromArray = (array: any[], finalSeparator: string) => {
  if (array.length === 1) return array[0];

  const priorValues = array.slice(0, -1);
  const [finalValue] = array.slice(-1);

  return `${priorValues.join(', ')} ${finalSeparator} ${finalValue}`;
};
