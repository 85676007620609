import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import getConfig from 'next/config';
import router from 'next/router';
import {
  Button,
  Theme,
} from '@havenengineering/module-shared-library/dist/components/Button';
import { LoadingIndicator } from '@havenengineering/module-shared-library/dist/components/LoadingIndicator';
import CheckboxInput from '@havenengineering/module-shared-library/dist/form/components/CheckboxInput';
import RadioSelectInput from '@havenengineering/module-shared-library/dist/form/components/RadioSelectInput';
import clsx from 'clsx';
import debounce from 'lodash/debounce';

import { LettingsContext } from '../../contexts/lettings';
import { fetchWrapper, withApiBaseUrl } from '../../helpers/fetch';
import { capitalizeFirstLetter } from '../../helpers/general';
import {
  handleGTMBannerImpression,
  handleGTMLettingCalcWidgetInteraction,
} from '../../helpers/googleTag';
import { BreakSlider } from './BreakSlider/BreakSlider';
import styles from './EarningPotential.module.scss';

interface EarningPotentialByRange {
  numberOfBreaks: number;
  flexiMin: number;
  flexiMax: number;
  fixedMin: number;
  fixedMax: number;
}

interface EarningPotentialResponse {
  peak: EarningPotentialByRange[];
  includeOffPeak: EarningPotentialByRange[];
  gradeDescription: string;
}

enum LettingPlan {
  FLEXI = 'flexi',
  FIXED = 'fixed',
}

interface EarningPotentialProps {
  pitchStatus: number;
  accountId: number;
  fullWidth?: boolean;
}

const OW_PITCH_STATUS = 7;

export const EarningPotential: FunctionComponent<EarningPotentialProps> = ({
  accountId,
  pitchStatus,
  fullWidth = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [allPrices, setAllPrices] = useState<EarningPotentialResponse>();

  const [allBreaksConsidered, setAllBreaksConsidered] = useState(0);
  const [breaksInCalculation, setBreaksInCalculation] = useState(0);

  const [offPeakToInclude, setOffPeakToInclude] = useState(0);

  const [lettingOptions, setLettingOptions] = useState<LettingPlan[]>([]);
  const [lettingPlan, setLettingPlan] = useState<LettingPlan>(
    LettingPlan.FIXED
  );
  const [includeOffPeak, setIncludeOffPeak] = useState(true);

  const [singlePlanMode, setSinglePlanMode] = useState<boolean>();

  const [priceRange, setPriceRange] = useState<{
    min: number | undefined;
    max: number | undefined;
  }>({
    min: 0,
    max: 0,
  });

  const {
    publicRuntimeConfig: { PUBLIC_ENV },
  } = getConfig();

  const isProduction = PUBLIC_ENV === 'production';

  const { lettingSummary } = useContext(LettingsContext);

  const [siteFees, setSiteFees] = useState<number>(0);

  const [siteFeesPercentage, setSiteFeesPercentage] = useState<number>(0);

  const getSiteFeesPercentage = useCallback(
    (
      potentialBreaks: EarningPotentialByRange[],
      breakCount: number,
      lettingType: string,
      totalSiteFees: number
    ) => {
      if (potentialBreaks?.length) {
        const price = potentialBreaks?.find(
          (price: EarningPotentialByRange) =>
            price.numberOfBreaks === breakCount
        );

        const isFixed = lettingType === LettingPlan.FIXED;

        const totalPrice = isFixed ? price?.fixedMax : price?.flexiMax;

        if (totalPrice && totalSiteFees) {
          return Math.round((totalSiteFees / +totalPrice?.toFixed(2)) * 100);
        }
      }
      return 0;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const loadPrices = async (accountId: number) => {
      setLoading(true);
      try {
        const prices = await fetchWrapper(
          withApiBaseUrl(
            `/letting-prices-forecast/earning-potential?accountId=${accountId}`
          ),
          {
            method: 'POST',
            credentials: 'include',
          }
        );

        const singlePlan = prices?.lettingOptions?.length === 1;
        const defaultLettingPlan = singlePlan
          ? prices?.lettingOptions?.[0]
          : LettingPlan.FIXED;

        setAllPrices(prices);
        setLettingPlan(defaultLettingPlan);
        setSinglePlanMode(singlePlan);
        setLettingOptions(prices?.lettingOptions || []);
        setAllBreaksConsidered(prices?.includeOffPeak?.length);
        setBreaksInCalculation(prices?.includeOffPeak?.length);
        setOffPeakToInclude(
          prices?.includeOffPeak?.length - prices?.peak?.length
        );

        if (!isProduction && prices?.siteFees) {
          setSiteFees(prices?.siteFees);
          const percentage = getSiteFeesPercentage(
            prices?.includeOffPeak,
            prices?.includeOffPeak?.length,
            defaultLettingPlan,
            prices?.siteFees
          );

          if (percentage) {
            setSiteFeesPercentage(percentage);
          }
        }

        if (prices?.includeOffPeak?.length > 0) {
          setTimeout(() => {
            handleGTMBannerImpression('banner impression', {
              button_cta: 'Find out more',
              image_url: 'noImage',
              link_url: '/lettings-hub/pricing-forecast',
              page_position: 'top',
              title: 'More owners are letting with Haven',
              variant: 'letting-calculator-advert',
            });
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (
      accountId &&
      pitchStatus === OW_PITCH_STATUS &&
      !lettingSummary?.let2Own
    ) {
      loadPrices(accountId);
    }
  }, [
    accountId,
    getSiteFeesPercentage,
    pitchStatus,
    isProduction,
    lettingSummary,
  ]);

  useEffect(() => {
    if (!allPrices) {
      return;
    }
    const prices = includeOffPeak ? allPrices?.includeOffPeak : allPrices?.peak;
    setAllBreaksConsidered(prices?.length);

    const price = prices.find(
      (price: EarningPotentialByRange) =>
        price.numberOfBreaks === breaksInCalculation
    );

    setPriceRange({
      min:
        lettingPlan === LettingPlan.FIXED ? price?.fixedMin : price?.flexiMin,
      max:
        lettingPlan === LettingPlan.FIXED ? price?.fixedMax : price?.flexiMax,
    });

    if (!isProduction && siteFees) {
      const percentage = getSiteFeesPercentage(
        prices,
        prices?.length,
        lettingPlan,
        siteFees
      );
      setSiteFeesPercentage(percentage);
    }
  }, [
    allPrices,
    breaksInCalculation,
    getSiteFeesPercentage,
    includeOffPeak,
    lettingPlan,
    isProduction,
    siteFees,
  ]);

  const toCurrency = (value: number) =>
    value.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

  const displayPrice = () => {
    if (!priceRange?.min || !priceRange?.max) return;

    if (priceRange?.min === priceRange?.max) {
      return `${toCurrency(priceRange?.min)}`;
    } else {
      return `${toCurrency(priceRange?.min)} – ${toCurrency(priceRange?.max)}`;
    }
  };

  const handleFindOurMore = () => {
    handleGTMBannerImpression('banner click', {
      button_cta: 'Find out more',
      image_url: 'noImage',
      link_url: '/lettings-hub/pricing-forecast',
      page_position: 'top',
      title: 'More owners are letting with Haven',
      variant: 'letting-calculator-advert',
    });

    setTimeout(() => {
      router.push({
        pathname: '/lettings-hub/pricing-forecast',
        query: { type: lettingPlan },
      });
    }, 500);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGTMScrollInteraction = useCallback(
    debounce(
      () => handleGTMLettingCalcWidgetInteraction({ fieldName: 'scroll' }),
      1000
    ),
    []
  );

  const handleLettinTypeSelection = (selected: string | null) => {
    handleGTMLettingCalcWidgetInteraction({
      fieldName: lettingPlan.toString() as 'flexi' | 'fixed',
    });
    selected && setLettingPlan(selected as LettingPlan);
  };

  const handleIncludePeakDates = () => {
    handleGTMLettingCalcWidgetInteraction({
      fieldName: 'select peak',
    });
    setIncludeOffPeak((prev) => !prev);
  };

  const handleSelectBreaksInCalc = (breaks: number) => {
    debouncedGTMScrollInteraction();
    setBreaksInCalculation(breaks);
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator loading />
      ) : (
        <>
          {allBreaksConsidered > 0 && (
            <div
              className={clsx(
                styles.sliderContainer,
                fullWidth && styles.fullWidth
              )}
            >
              <div className={styles.topContainer}>
                <div className={styles.title}>
                  More owners are letting with Haven.
                </div>

                <div className={styles.description}>
                  (The more breaks you let with us, the more you could save)
                </div>

                <div className={styles.actionWrapper}>
                  <div className={styles.lettingPlanRadio}>
                    <p>Letting plan:</p>
                    {singlePlanMode ? (
                      <p className={styles.lettingPlanSingle}>
                        {capitalizeFirstLetter(lettingPlan)}
                      </p>
                    ) : (
                      <RadioSelectInput
                        id="lettingPlanRadio"
                        options={lettingOptions?.map((item: string) => ({
                          label: item.charAt(0).toUpperCase() + item.slice(1),
                          value: item,
                        }))}
                        label="Letting plan"
                        value={lettingPlan}
                        onChange={handleLettinTypeSelection}
                        horizontal
                      />
                    )}
                  </div>

                  <div className={styles.offPeakCheckbox}>
                    <CheckboxInput
                      id="includeOffPeak"
                      value={includeOffPeak}
                      onChange={handleIncludePeakDates}
                      label={`Include off-peak breaks (${offPeakToInclude})`}
                    />
                  </div>
                </div>

                <hr className={styles.displayDivider} />

                <div className={styles.gradeDescription}>
                  {allPrices?.gradeDescription}
                </div>
                {!isProduction && siteFeesPercentage > 0 && (
                  <div className={styles.siteFeeContainer}>
                    <div
                      className={styles.siteFeeDashed}
                      style={{ left: `${siteFeesPercentage}%` }}
                      data-testid="siteFees-percentage"
                    >
                      <p>Average site fees</p>
                      <hr></hr>
                    </div>
                  </div>
                )}
                <BreakSlider
                  max={allBreaksConsidered}
                  current={breaksInCalculation}
                  onChange={handleSelectBreaksInCalc}
                />
              </div>

              <div className={styles.pointer}></div>

              <div className={styles.bottomContainer}>
                <div className={styles.earn}>Potentially earn</div>
                <div className={styles.priceRange}>{displayPrice()}</div>
                <Button
                  onClick={handleFindOurMore}
                  theme={Theme.PRIMARY_THEME}
                  icon="/assets/icon-navchevron-right.svg"
                  iconLast
                >
                  Find out more
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
