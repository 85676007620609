import React, { FunctionComponent, useEffect, useState } from 'react';

import { useBooking } from './bookingContext';
import BookingEditor from './BookingEditor';

interface ArrivalBookingEditProps {
  handleArrivalBookingChange: (booking: ArrivalBooking) => void;
}

export const ArrivalBookingEdit: FunctionComponent<ArrivalBookingEditProps> = ({
  handleArrivalBookingChange,
}) => {
  const { booking } = useBooking();
  const [sendChange, setSendChange] = useState(false);

  useEffect(() => {
    if (sendChange) {
      handleArrivalBookingChange(booking);
    }
    setSendChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  return <BookingEditor isLetting={booking.isLetting} />;
};
