export enum DrainDownOption {
  basic = 'non-ch',
  heating = 'ch',
  lodge = 'lodge',
  diyLodge = 'diyLodge',
  diyCaravan = 'diyCaravan',

  essentialCh = 'essential_central_heating__caravan_',
  essentialNonCh = 'essential_no_central_heating__caravan_',
  essentialLodge = 'essential__lodge_',

  advancedCh = 'advanced_central_heating__caravan_',
  advancedNonCh = 'advanced_no_central_heating__caravan_',
  advancedLodge = 'advanced__lodge_',

  premiumCh = 'premium_central_heating__caravan_',
  premiumNonCh = 'premium_no_central_heating__caravan_',
  premiumLodge = 'premium__lodge_',
}
