import { useMemo } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { swrFetcher } from '../helpers/fetch';
import { LettingAdvertForBanner } from '../types/lettings';
import { Park } from '../types/parks';

type Response = {
  actionRequired: boolean;
  waitlist: Advert[];
  nonWaitlist: Advert[];
  formattedAd: LettingAdvertForBanner | null;
};

type Options = {
  getActionRequired?: boolean;
  getSeparateWaitlist?: boolean;
  getFormattedAd?: boolean;
};

export const useLettingAdverts = (
  accountID: number | undefined,
  options: Options
): Response => {
  const { data } = useSWR<Advert[]>(
    accountID ? `/plot/${accountID}/lettingbreaks/adverts` : null,
    swrFetcher
  );

  const matchedAd = useMemo(() => data?.find((x) => !x.waitlist), [data]);
  const park = useSWRImmutable<Park>(
    matchedAd?.parkCode && `/parks/${matchedAd.parkCode}`,
    swrFetcher
  ).data;

  const actionRequired = useMemo(
    () =>
      options.getActionRequired && data
        ? !!data.filter((ad) => ad.waitlist).length
        : false,
    [data, options.getActionRequired]
  );

  const { waitlist, nonWaitlist } = useMemo(
    () =>
      options.getSeparateWaitlist && data
        ? data.reduce(
            (acc: { waitlist: Advert[]; nonWaitlist: Advert[] }, ad) => {
              if (ad.waitlist) {
                acc.waitlist.push(ad);
                return acc;
              }
              acc.nonWaitlist.push(ad);
              return acc;
            },
            { waitlist: [], nonWaitlist: [] }
          )
        : { waitlist: [], nonWaitlist: [] },
    [data, options.getSeparateWaitlist]
  );

  const formattedAd = useMemo(() => {
    if (!options.getFormattedAd || !matchedAd) return null;
    return {
      id: matchedAd?.id,
      title: matchedAd?.title,
      heading: 'HAVEN LETTINGS TEAM',
      text: matchedAd?.customMessage,
      imageUrl: matchedAd?.image,
      ctaText: matchedAd?.ctaText,
      contactDetails: {
        name: matchedAd?.lettingAdministrator,
        jobTitle: `Adminstrator, ${park?.parkName}`,
        phone: matchedAd?.lettingAdministratorPhone,
        email: matchedAd?.lettingAdministratorEmail,
      },
    };
  }, [matchedAd, options.getFormattedAd, park?.parkName]);

  return {
    actionRequired,
    waitlist,
    nonWaitlist,
    formattedAd,
  };
};
