export interface OnboardingTooltip {
  id: string;
  text: string;
  type: TooltipType;
  dismissedForSession?: boolean;
}

export enum TooltipType {
  CALENDAR_EVENT_ADD = 'CALENDAR_EVENT_ADD',
  CALENDAR_EVENT_AMEND = 'CALENDAR_EVENT_AMEND',
  CALENDAR_EVENT_BOOK = 'CALENDAR_EVENT_BOOK',
  CALENDAR_EVENT_CHECKBOX = 'CALENDAR_EVENT_CHECKBOX',
  CALENDAR_EVENT_DETAIL = 'CALENDAR_EVENT_DETAIL',
  NAVIGATION_YOUR_HOLIDAY_HOME = 'NAVIGATION_YOUR_HOLIDAY_HOME',
}
