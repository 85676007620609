import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { getlabelBadgeStyleByCode, isOffer } from '../../helpers/calendar';
import styles from './LabelBadge.module.scss';

type LabelBadgeProps = {
  code?: number;
  value?: string;
  label: string;
};

export const LabelBadge: FunctionComponent<LabelBadgeProps> = ({
  label,
  code,
  value,
}) => {
  return (
    <>
      {code && isOffer(code) ? (
        <div className={styles.dashedWrapper}>
          <div className={styles.dashedLine} />
          <span>{value || 0}</span>
          <div className={styles.dashedLine} />
        </div>
      ) : (
        <p
          className={clsx(
            styles.badge,
            styles[getlabelBadgeStyleByCode(code) || label]
          )}
          data-testid="label-badge"
        >
          {label}
        </p>
      )}
    </>
  );
};
