import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';
import {
  Accordion,
  AccordionSize,
} from '@havenengineering/module-shared-library/dist/components/Accordion/Accordion';
import InlineMessaging, {
  InlineMessagingTheme,
} from '@havenengineering/module-shared-library/dist/components/InlineMessaging/InlineMessaging';
import { SelectInputField } from '@havenengineering/module-shared-library/dist/form/components/SelectInput';
import { TextInputField } from '@havenengineering/module-shared-library/dist/form/components/TextInput';
import useForm, {
  ukPhoneValidator,
} from '@havenengineering/module-shared-library/dist/form/hooks/useForm';

import { hasPlayPassTrial } from '../../../helpers/calendar';
import {
  areDateRangesOverlapping,
  DateRange,
  getDateTimeFromIso,
} from '../../../helpers/dateTime';
import { fetchWrapper, withApiBaseUrl } from '../../../helpers/fetch';
import { EventDateSelection } from '../../../pages/bookings';
import ParticipantForm from '../../BookingDetails/ParticipantForm';
import { CounterButtons } from '../../CounterButtons/CounterButtons';
import { getGuestSelectorOptions } from '../../helpers/bookings';
import { ArrivalBookingForm, BookingType } from '../Booking';
import styles from './PersonDetailsStep.module.scss';
import { Stepper } from './Stepper';

type PersonDetailsStepProps = {
  bookingType: BookingType;
  handleNext: (data: any) => void;
  handlePrev: () => void;
  form: Partial<ArrivalBookingForm>;
  nextIsDisabled: boolean;
  bookingError: string;
  ownersCards: OwnersCardResponse | null;
  handleSetInfo: (message: string) => void;
  selectionDates: EventDateSelection;
};

export const PersonDetailsStep: FunctionComponent<PersonDetailsStepProps> = ({
  bookingType,
  handleNext,
  handlePrev,
  form,
  nextIsDisabled,
  bookingError,
  ownersCards,
  handleSetInfo,
  selectionDates,
}) => {
  const { activeAccount } = useAuthContext();
  const [childCount, setChildCount] = useState(form.childNumber || 0);
  const [infantsCount, setInfantsCount] = useState(form.toddlersNumber || 0);
  const [participants, setParticipants] = useState<Participant[]>(
    form.participants || []
  );
  const [selectedForEdit, setSelectedForEdit] = useState<number>(-1);
  const [showForm, setShowForm] = useState(false);
  const [isPlayPassRestricted, setIsPlayPassRestricted] =
    useState<boolean>(true);

  const guestSelectorOptions = useMemo(
    () =>
      getGuestSelectorOptions({
        ownersCards,
        parkCode: activeAccount?.parkCode,
        isPlayPassRestricted,
      }),
    [activeAccount, ownersCards, isPlayPassRestricted]
  );

  const { fields, submit } = useForm<any>({
    initialState: {
      firstName: form.firstName || '',
      lastName: form.lastName || '',
      letterEmail: form.letterEmail || '',
      phoneNumber: form.phoneNumber || '',
      childNumber: form.childNumber || 0,
      toddlersNumber: form.toddlersNumber || 0,
      privCardHolder: form.privCardHolder || '',
    },
    schema: {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      letterEmail: {
        required: bookingType === BookingType.PRIVATE_LET,
      },
      phoneNumber: {
        required: true,
        validator: ukPhoneValidator,
      },
    },
    submitCallback: async (state) => {
      handleNext({
        ...state,
        childNumber: childCount,
        toddlersNumber: infantsCount,
        participants,
      });
    },
  });

  useEffect(() => {
    const details = ownersCards?.nominees?.find(
      (card) => card.id == fields.privCardHolder.value
    );
    if (details) {
      fields.firstName.setValue(details?.firstName || '');
      fields.lastName.setValue(details?.lastName || '');
      fields.phoneNumber.setValue(details?.phoneNumber || '');
      fields.letterEmail.setValue(details?.email || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.privCardHolder.value]);

  useEffect(() => {
    return () => {
      handleSetInfo('');
    };
  }, [handleSetInfo]);

  useEffect(() => {
    const getPlayPassRestrictions = async (parkCode?: string) => {
      if (!parkCode) return;

      try {
        const restrictions = await fetchWrapper(
          withApiBaseUrl(`/play-pass-restrictions/${parkCode}`),
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        const restrictedDates = restrictions.map(
          (restriction: {
            parkCode: string;
            startDate: string;
            endDate: string;
          }) => {
            return {
              startDate: getDateTimeFromIso(restriction.startDate),
              endDate: getDateTimeFromIso(restriction.endDate),
            };
          }
        );

        const overlappingRestriction = restrictedDates.find(
          (dateRange: DateRange) =>
            areDateRangesOverlapping(dateRange, selectionDates)
        );

        setIsPlayPassRestricted(!!overlappingRestriction);
      } catch (error) {
        console.error(error);
      }
    };

    if (hasPlayPassTrial(activeAccount?.parkCode)) {
      getPlayPassRestrictions(activeAccount?.parkCode);
    }
  }, [activeAccount, selectionDates]);

  const handleParticipantSave = (participant: Participant) => {
    setParticipants((prev) => [...prev, participant]);
    setShowForm(false);
  };

  const handleParticipantEdit = (participant: Participant) => {
    setParticipants((prev) => {
      const b = [...prev];
      b[selectedForEdit] = participant;
      return b;
    });
    setSelectedForEdit(-1);
  };

  const handleDeleteParticipant = (index: number) => {
    setParticipants((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleCancelEdit = () => {
    setSelectedForEdit(-1);
    setShowForm(false);
  };

  return (
    <div className={styles.mainContainer}>
      {bookingType === BookingType.OWNER && (
        <h1 className={styles.label}>Your details</h1>
      )}
      {bookingType === BookingType.PRIVATE_LET && (
        <h1 className={styles.label}>Your Guest&apos;s details</h1>
      )}
      <p className={styles.text}>
        Please provide contact details of the lead guest. We will only contact
        them should we need to before or during their stay.
      </p>
      <form
        className={styles.editorContainer}
        onSubmit={(event) => event.preventDefault()}
      >
        {bookingType !== BookingType.PRIVATE_LET && (
          <>
            <TextInputField
              className="fs-mask"
              id="firstName"
              label="First name"
              field={fields.firstName}
              type="text"
            />
            <TextInputField
              className="fs-mask"
              id="lastName"
              label="Surname"
              field={fields.lastName}
              type="text"
            />
            <TextInputField
              className="fs-mask"
              id="phoneNumber"
              label="Contact number"
              field={fields.phoneNumber}
              type="text"
            />
          </>
        )}
        {bookingType === BookingType.PRIVATE_LET && (
          <>
            {guestSelectorOptions.length > 0 && (
              <SelectInputField
                className="fs-mask"
                id="privCardHolder"
                label="Guest Options"
                field={fields.privCardHolder}
                options={guestSelectorOptions as any}
              />
            )}
            <TextInputField
              className="fs-mask"
              id="firstName"
              label="Lead guest first name"
              field={fields.firstName}
              type="text"
            />
            <TextInputField
              className="fs-mask"
              id="lastName"
              label="Lead guest surname"
              field={fields.lastName}
              type="text"
            />
            <TextInputField
              className="fs-mask"
              id="phoneNumber"
              label="Contact number"
              field={fields.phoneNumber}
              type="text"
            />
            <TextInputField
              className="fs-mask"
              id="letterEmail"
              label="Email address"
              field={fields.letterEmail}
              type="text"
            />
          </>
        )}
      </form>

      {bookingType === BookingType.PRIVATE_LET && (
        <>
          <h1 className={styles.label}>Additional guests</h1>
          <div className={styles.row}>
            <p>
              <strong>Adults</strong>
              <br />
              <small>16+ yrs</small>
            </p>
            <Button
              className={styles.iconButton}
              variant="outlined"
              onClick={() => setShowForm(true)}
              type="button"
              disabled={selectedForEdit >= 0}
              data-testid="add-adult-btn"
            >
              <img
                src="https://www.haven.com/assets/icons/plus.svg"
                width={24}
                height={24}
                alt="add"
              />
            </Button>
          </div>
          {participants.map((participant, idx) => (
            <div
              key={`${participant.name}-${participant.phoneNumber}`}
              className={styles.row}
            >
              <p>
                <strong>{participant.name}</strong>
                <br />
                <small>{participant.phoneNumber}</small>
              </p>
              <div>
                <Button
                  className={styles.iconButton}
                  variant="outlined"
                  disabled={showForm}
                  onClick={() => handleDeleteParticipant(idx)}
                  type="button"
                  data-testid={`delete-${idx}`}
                >
                  <img
                    src="/assets/icon-delete.svg"
                    width={24}
                    height={24}
                    alt="add"
                  />
                </Button>
                <Button
                  className={styles.iconButton}
                  variant="outlined"
                  disabled={showForm}
                  onClick={() => setSelectedForEdit(idx)}
                  type="button"
                  data-testid={`edit-${idx}`}
                >
                  <img
                    src="https://www.haven.com/assets/icons/pencil-blue.svg"
                    width={24}
                    height={24}
                    alt="add"
                  />
                </Button>
              </div>
            </div>
          ))}
          {selectedForEdit >= 0 && (
            <ParticipantForm
              participant={participants[selectedForEdit]}
              handleSaveParticipant={handleParticipantEdit}
              handleCancel={() => handleCancelEdit()}
            />
          )}
          {showForm && (
            <ParticipantForm
              handleSaveParticipant={handleParticipantSave}
              handleCancel={() => handleCancelEdit()}
            />
          )}

          <div className={styles.row}>
            <p>Children</p>
            <div>
              <CounterButtons
                count={childCount}
                onDecrement={(count) => setChildCount(count)}
                onIncrement={(count) => setChildCount(count)}
                testId="children"
              />
            </div>
          </div>
          <div className={styles.row}>
            <p>Infants</p>
            <div>
              <CounterButtons
                count={infantsCount}
                onDecrement={(count) => setInfantsCount(count)}
                onIncrement={(count) => setInfantsCount(count)}
                testId="infants"
              />
            </div>
          </div>
        </>
      )}
      <Accordion
        title="Read how we process this data"
        size={AccordionSize.SMALL}
      >
        <p>
          This information has been intentionally minimised to ensure we are
          only collecting the data that is necessary to enable us to ensure
          efficiencies on arrival, maintain social distancing measures and
          communicate with you in case of an emergency. We will retain this data
          for a maximum of 30 days after you or your guest has departed. At the
          end of that period your data will either be deleted completely or
          anonymised. Data Protection principles will, of course, apply as usual
          and your data will not be further processed.
        </p>
      </Accordion>

      {bookingError && (
        <InlineMessaging
          type={InlineMessagingTheme.ERROR_THEME}
          title="Error"
          message={bookingError}
          dismissible={false}
        />
      )}

      <Stepper
        handleNext={() => submit()}
        handlePrev={() => handlePrev()}
        nextIsDisabled={nextIsDisabled}
        prevIsDisabled={false}
        dotsCount={3}
        activeDot={2}
        nextLabel="Book"
      />
    </div>
  );
};
